function buildNormalizedFields(
  formFields: { [key: string]: unknown },
  failedFields: FailedFields,
): NormalizedFailedFields[] {
  const normalizedFields: NormalizedFailedFields[] = [];
  const fields: string[] = Object.keys(failedFields);

  for (const key of fields) {
    if (failedFields[key].length === undefined) {
      Object.keys(failedFields[key]).forEach((k) =>
        normalizedFields.push({
          name: `${key}.${k}`,
          errors: failedFields[key][k],
        }),
      );
    } else {
      normalizedFields.push({
        name: key,
        errors: failedFields[key],
      });
    }
  }

  return normalizedFields;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const validateResponse: any = (form?: any, err?: any, values?: any) => {
  if (err.response) {
    const errors = err.response.data;
    // FIXME: Add functionality to handle errors for the array and object value
    const fieldsErrors = Object.keys(errors || {}).map((k) => {
      return {
        name: k,
        errors: errors[k],
      };
    });
    form.setFields(fieldsErrors);
  } else if (err.request) {
    // client never received a response, or request never left
    console.error('Request error:', err.request);
  } else {
    // anything else
    console.log('Validation error', err);
  }
};

/**
 * API returns an object with fieldName: string[] structure
 * for form fields that failed validations.
 *
 * This are used to convert to `NormalizedFailedFields`.
 */
type FailedFields = { [key: string]: string[] };

/**
 * Data structure for antd form validation, this can be
 * used with `form.setFields` method.
 *
 * @See https://github.com/react-component/form/blob/3b9959b57ab30b41d8890ff30c79a7e7c383cad3/examples/server-validate.js#L74-L79
 */
type NormalizedFailedFields = { name: string; errors: string[] };

type NonFieldErrors = string[] | null;

/**
 * An error class, typically used to indicate
 * some sort of validation error in the backend.
 *
 * @example
 * throw new ValidationError(form, failed);
 *
 * @example
 * throw new ValidationError(form, failed, null);
 */
class ValidationError extends Error {
  readonly messages: string[];
  readonly fields: NormalizedFailedFields[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly response: any;

  /**
   * Ctor.
   *
   * @param body - the object sent to the API, usually taken from antd form.
   * @param data - this is the API response with information about fields that failed validation.
   * @param nonFields - optional messages.
   */
  constructor(
    body: { [key: string]: unknown },
    data: FailedFields,
    nonFields: NonFieldErrors = null,
  ) {
    super('Failed validation');
    this.fields = buildNormalizedFields(body, data);
    this.response = data;

    if (nonFields === null) {
      this.messages = [];
    } else {
      this.messages = nonFields;
    }
  }
}

export default ValidationError;
