import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSetState } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Space, Button, Icon, Card, useNotify, Loader } from 'ebs-design';
import { ConfirmModal } from 'components/molecules/ConfirmModal';
import { Plus, Delete } from 'resources/icons';
import { CategoryForm } from '../CategoryForm';
import { useCategoriesAPI } from 'api';
import { CategoryImage, Properties } from 'types';
import { format, dateFormatAPI } from 'libs';
import cn from 'classnames';

interface defaultStateToProps {
  category?: Properties;
  visibleDeleteModal: boolean;
  id: number | null;
}

const defaultState = {
  category: undefined,
  visibleDeleteModal: false,
  id: null,
};

export const CategoriesDetails: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { getImagesByCategoryId, deleteCategoryImage } = useCategoriesAPI();
  const [state, setState] = useSetState<defaultStateToProps>(defaultState);

  const { data: categoryImages, isLoading } = useQuery<CategoryImage[]>(
    ['category-images', { id }],
    getImagesByCategoryId,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const deleteCategoryImageMutation = useMutation((id: number) => deleteCategoryImage(id), {
    onError: () =>
      notify.error({
        title: t('error.someThingIsWrong'),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['category-images']);
      notify.success({ title: t('success.successDataDelete') });
      setState({ visibleDeleteModal: false, id: null });
    },
  });

  const handleDelete = (): void => {
    if (typeof state.id === 'number') {
      deleteCategoryImageMutation.mutate(state.id);
    }
  };

  const handleCloseModal = (): void => {
    setState(defaultState);
  };
  return (
    <>
      <Space justify="space-between" className="mb-20">
        <Space>
          <h3 className="title">{t('promotions.categoryDetails')}</h3>
        </Space>
        <Space>
          <Link to={'/categories'}>
            <Button>{t('buttons.back')}</Button>
          </Link>
          <Button
            type="primary"
            prefix={<Icon component={Plus} />}
            onClick={() => {
              setState({ category: {} });
            }}
          >
            {t('buttons.addImage')}
          </Button>
        </Space>
      </Space>
      <Card>
        <Card.Header className="border-bottom__none">
          <Space align="start">
            <h3 className="title">{`${t(`promotions.uploadedImages`)}${
              categoryImages ? ` (${categoryImages?.length})` : ``
            }`}</h3>
          </Space>
        </Card.Header>
        <Card.Body className="bgr-color">
          <Loader loading={isLoading} size="regular">
            {categoryImages?.length && (
              <Space wrap>
                {categoryImages?.map(({ image, state, start_date, id }) => (
                  <div className="categories-box__thumb" key={id}>
                    <img className="categories-box__image" src={image} />

                    <Space className="categories-box__card" justify="space-between">
                      <div className={cn('card-type', { [`card-type--${state}`]: state })}>
                        {state !== 'old' && (
                          <Icon className="card-type__check" model="bold" type="check" />
                        )}
                      </div>
                      <div className="box-card-btn">
                        <div className="card-type category-time">
                          {format(start_date, dateFormatAPI)}
                        </div>
                        {state !== 'current' && (
                          <Button
                            className="btn-delete"
                            onClick={() => setState({ visibleDeleteModal: true, id })}
                          >
                            <Icon component={Delete} className="btn-delete__icon" />
                          </Button>
                        )}
                      </div>
                    </Space>
                  </div>
                ))}
              </Space>
            )}
          </Loader>
        </Card.Body>
      </Card>
      {state.category && <CategoryForm onClose={handleCloseModal} />}

      {!!state.visibleDeleteModal && (
        <ConfirmModal
          onSuccess={handleDelete}
          onCancel={() => setState(defaultState)}
          content={t('confirm.areYouSureImages')}
        />
      )}
    </>
  );
};
