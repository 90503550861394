import * as React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Space, Row, Col, useForm, useNotify } from 'ebs-design';
import { ValidationError } from 'errors';
import { useLoginAPI } from 'api';
import { Login } from 'types';
import { InputPassword } from 'components/molecules/InputPassword';
import { SessionContext } from 'contexts/SessionContext';
import { Tokens } from 'hooks/useFetcher/interfaces';

export const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const notify = useNotify();
  const history = useHistory();
  const { login } = useLoginAPI();

  const { setTokens } = React.useContext(SessionContext);

  const mutation = useMutation<Tokens, unknown, Login>(login, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);
        notify.error({ title: t('error.someThingIsWrong') });
      } else {
        notify.error({ title: t('error.loginFail') });
      }
    },
    onSuccess: (response: Tokens) => {
      setTokens(response);
      history.push('/');
    },
  });

  const handleSubmit = (data: Login): void => {
    mutation.mutate(data);
  };

  return (
    <Form
      form={form}
      type="horizontal"
      onFinish={handleSubmit}
      controlOptions={{ col: { size: 9 } }}
      labelOptions={{ col: { size: 3 } }}
    >
      <Form.Field name="email" label={t('email')}>
        <Input type="email" />
      </Form.Field>

      <Form.Field name="password" label={t('password')}>
        <InputPassword />
      </Form.Field>

      <Row className="mt-15">
        <Col size={3} />

        <Col size={9}>
          <div className="authentication-modal-form-actions">
            <Space justify="space-between">
              <Button type="primary" submit buttonClass="uppercase" className="full-width">
                {t('buttons.login')}
              </Button>
            </Space>
            <Space justify="end">
              <Link to={'/forgot-password'}>{t('form.forgot_password')}</Link>
            </Space>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
