import { ServiceConfig } from 'hooks/useFetcher/interfaces';
import { ProfileUser } from 'types';
export type FetchUserResult = [Error | null, ProfileUser | null];

export const fetchUser = async (config: ServiceConfig, token: string): Promise<FetchUserResult> => {
  const url = `${config.baseUrl}/dashboard/me`;
  let session: ProfileUser | null = null;
  let error: Error | null = null;

  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    });
    const data = await res.json();

    if (res.ok) {
      session = data;
    } else {
      // error = new ResponseError(url, res.status, data, res);
    }
  } catch (e: any) {
    error = e;
  }

  return [error, session];
};
