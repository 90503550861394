import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSetState } from 'react-use';
import { Space } from 'ebs-design';
import { Filters } from 'components/molecules';
import { defaultFilters } from 'utils';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';

interface PromotionsFilterProps {
  count?: number;
}

interface FiltersProps {
  search?: string;
}

export const PromotionsFilter: React.FC<PromotionsFilterProps> = ({ count }) => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();

  const [filters, setFilters] = useSetState<FiltersProps>({
    ...defaultFilters,
    ...params,
  });

  React.useEffect(() => {
    updateQuery(filters);
  }, [filters, updateQuery]);

  const handleFilters = (value): void => {
    setFilters(value);
  };

  return (
    <>
      <Filters onChange={handleFilters}>
        <Space>
          <Space>
            <h3 className="title">{`${t(`menu.deals`)}${count ? ` (${count})` : ``}`}</h3>
          </Space>
          <Filters.Search
            value={filters.search}
            field="search"
            placeholder={t('filter.searchById')}
          />
        </Space>
      </Filters>
    </>
  );
};
