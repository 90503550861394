import { useFileAPI } from 'api';
import { UploadedFile } from 'types';
import { format, dateTimeFormatAPI } from 'libs';
import { uploadFmDataBanner } from './utils';

interface UploadProps {
  promotionsRequest: (data: any) => UploadedFile;
  promotionsInactiveRequest: (data: any) => Promise<UploadedFile>;
  splashScreenPOSTRequest: (data: any) => Promise<UploadedFile>;
  splashScreenPATCHRequest: (data: any) => Promise<UploadedFile>;
  categoryImagesPOSTRequest: (data: any) => Promise<UploadedFile>;
  bannerPOSTRequest: (data: any) => Promise<UploadedFile>;
  bannerPATCHRequest: (data: any) => Promise<UploadedFile>;
}

export const useUpload = (): UploadProps => {
  const { uploadFile } = useFileAPI();

  return {
    promotionsRequest: (options) => {
      const { id, file } = options;
      const fmData = new FormData();
      if (id) {
        fmData.append('id', id);
      }
      fmData.append('id', id);
      fmData.append('image', file);
      return uploadFile(fmData, '/dashboard/promotions', 'POST');
    },

    promotionsInactiveRequest: async (options): Promise<UploadedFile> => {
      const { id, image } = options;
      const fmData = new FormData();
      if (id) {
        fmData.append('id', id);
      }
      if (image) {
        fmData.append('image', image);
      }
      fmData.append('id', id);
      fmData.append('image', image);
      return uploadFile(fmData, '/dashboard/inactive-promotions', 'POST');
    },

    splashScreenPOSTRequest: async (options): Promise<UploadedFile> => {
      const { id, image, title, display_seconds, start_date } = options;
      const fmData = new FormData();
      if (id) {
        fmData.append('id', id);
      }
      if (image) {
        fmData.append('image', image);
      }
      if (title) {
        fmData.append('title', title);
      }
      if (display_seconds) {
        fmData.append('display_seconds', display_seconds);
      }
      if (start_date) {
        fmData.append('start_date', format(start_date, dateTimeFormatAPI));
      }

      return uploadFile(fmData, '/dashboard/splash-screens', 'POST');
    },

    splashScreenPATCHRequest: async (options): Promise<UploadedFile> => {
      const { id, image, title, display_seconds, start_date } = options;
      const fmData = new FormData();
      if (id) {
        fmData.append('id', id);
      }
      if (image) {
        fmData.append('image', image);
      }
      fmData.append('title', title);
      fmData.append('display_seconds', display_seconds);
      fmData.append('start_date', format(start_date, dateTimeFormatAPI));

      return uploadFile(fmData, `/dashboard/splash-screens/${id}`, 'PATCH');
    },
    categoryImagesPOSTRequest: async (options): Promise<UploadedFile> => {
      const { id, image, category, start_date } = options;
      const fmData = new FormData();
      if (id) {
        fmData.append('id', id);
      }
      if (image) {
        fmData.append('image', image);
      }
      fmData.append('id', id);
      fmData.append('image', image);
      fmData.append('category', category);
      fmData.append('start_date', format(start_date, dateTimeFormatAPI));
      return uploadFile(fmData, '/dashboard/category-images', 'POST');
    },
    bannerPOSTRequest: async (options): Promise<UploadedFile> => {
      const fmData = uploadFmDataBanner(options);
      return uploadFile(fmData, '/dashboard/banners', 'POST');
    },

    bannerPATCHRequest: async (options): Promise<UploadedFile> => {
      const fmData = uploadFmDataBanner(options);
      return uploadFile(fmData, `/dashboard/banners/${options.type}`, 'PATCH');
    },
  };
};
