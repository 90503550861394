import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthModal } from '../AuthModal';
import { PasswordForm } from '../Form/PasswordForm';

export const ResetPassword: React.FC = () => {
  const { t } = useTranslation();

  return (
    <AuthModal title={t('form.reset_password')}>
      <PasswordForm />
    </AuthModal>
  );
};
