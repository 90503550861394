import * as React from 'react';
import { Layout as EBSLayout, Sidebar, Icon, Tooltip, NotifyContainer } from 'ebs-design';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserProfileTooltip } from '../../organisms/UserProfileTooltip';
import { UserCircle } from 'resources/icons';
import { Logo } from 'resources';
import { useModules } from 'hooks';

const { Topbar, Content, Footer } = EBSLayout;
const { TopMenu, BottomMenu, Item } = Sidebar;

const getChildrenItems = (children): undefined | JSX.Element => {
  if (!children) return;
  return (
    <>
      {Object.keys(children || {}).map((m) => (
        <Item
          key={`${module}-${m}`}
          text={<Link to={children[m].route}>{children[m].label}</Link>}
        />
      ))}
    </>
  );
};

export const Layout: React.FC = ({ children }) => {
  const { modules } = useModules();
  const { t } = useTranslation();

  return (
    <EBSLayout className="ebs-crm">
      <Topbar>
        <Topbar.Toggler />
        <Topbar.Title>
          <Icon component={Logo} />
        </Topbar.Title>
      </Topbar>
      <Sidebar>
        <TopMenu toggleText="Ascunde">
          {Object.keys(modules).map((module, i) => (
            <Link key={i} to={modules[module].route}>
              <Item
                prefix={
                  <Icon
                    {...(modules[module].iconType
                      ? { type: modules[module].iconType }
                      : { component: modules[module]?.iconComponent })}
                  />
                }
                text={modules[module].label}
                active={modules[module].active}
              >
                {getChildrenItems(modules[module].children)}
              </Item>
            </Link>
          ))}
        </TopMenu>
        <BottomMenu>
          <Tooltip
            bodyClass="p-0"
            placement="right"
            trigger="click"
            tooltip={<UserProfileTooltip />}
          >
            <Item
              prefix={<Icon component={UserCircle} />}
              text={t('menu.userProfile')}
              className="user-profile"
            />
          </Tooltip>
        </BottomMenu>
      </Sidebar>

      <Content>
        <NotifyContainer />
        {children}
      </Content>
      <Footer />
    </EBSLayout>
  );
};
