import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Input, Space, Row, Col, useForm, useNotify, Alert } from 'ebs-design';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'errors';
import { Login } from 'types';
import { useMutation } from 'react-query';
import { useLoginAPI } from 'api';
import { Tokens } from 'hooks/useFetcher/interfaces';

export const SentEmailForm: React.FC = () => {
  const [showAlert, setShowAlert] = React.useState(false);
  const [form] = useForm();
  const notify = useNotify();
  const { t } = useTranslation();
  const { forgotPassword } = useLoginAPI();

  const mutation = useMutation<Tokens, unknown, Login>(forgotPassword, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);
      } else {
        notify.error({ title: t('error.someThingIsWrong') });
      }
    },
    onSuccess: () => {
      setShowAlert(true);
    },
  });

  const handleSubmit = (data: Login): void => {
    mutation.mutate(data);
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      type="horizontal"
      controlOptions={{ col: { size: 9 } }}
      labelOptions={{ col: { size: 3 } }}
    >
      <Form.Field name="email" label={t('email')}>
        <Input type="email" />
      </Form.Field>

      <Row className="mt-15">
        <Col size={3} />

        <Col size={9}>
          <div className="authentication-modal-form-actions">
            <Space justify="space-between">
              <Link to="/login">
                <Button buttonClass="uppercase" className="full-width">
                  {t('buttons.login')}
                </Button>
              </Link>
              <Button type="primary" submit buttonClass="uppercase" className="full-width">
                {t('buttons.sent')}
              </Button>
            </Space>
          </div>
          {showAlert && <Alert className="my-15" message={t('form.alertForCheckEmail')}></Alert>}
        </Col>
      </Row>
    </Form>
  );
};
