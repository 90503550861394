import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';

export const useLoginAPI = (): any => {
  const fetcher = useFetcher(ServiceTypes.MAIN);

  return {
    login: (data) => {
      return fetcher('/dashboard/auth/login', {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    forgotPassword: (data) => {
      {
        return fetcher('/dashboard/auth/forgot-password', {
          data,
          request: {
            method: 'POST',
          },
        });
      }
    },
    resetPassword: (data) => {
      {
        return fetcher('/dashboard/auth/forgot-password/confirm', {
          data,
          request: {
            method: 'POST',
          },
        });
      }
    },
  };
};
