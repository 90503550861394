/*
 * this method is used for get some token from url
 * */
import { useLocation } from 'react-router-dom';

export const isCurrentURl = (url: string): boolean => {
  const location = useLocation();
  return url === location.pathname;
};

export const getTokenFromUrl = (): string => {
  return window.location.pathname
    .split('/')
    .filter((i) => i !== '')
    .slice(2, 3)
    .join();
};
