import * as React from 'react';
import { Card, Loader, useNotify } from 'ebs-design';
import { useTranslation } from 'react-i18next';
import { Pagination } from 'components/organisms/Pagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { defaultFilters } from 'utils';
import { yearBirthday, dateFormatAPI } from 'libs';
import { UsersFilter } from '../Filters/UsersFilter';
import { UsersTable } from '../index';
import { useQuery } from 'react-query';
import { useUsersAPI } from 'api';
import { Results, User } from 'types';

export const Users: React.FC = () => {
  const { birthday_from, birthday_to, ...params } = useQueryParams();
  const { getUsers } = useUsersAPI();
  const { t } = useTranslation();
  const notify = useNotify();

  const { data: users, isLoading } = useQuery<Results<User>>(
    [
      'users',
      {
        ...defaultFilters,
        ...params,
        birthday__lte:
          birthday_from &&
          yearBirthday(parseInt(birthday_from) || 0)
            .endOf('year')
            .format(dateFormatAPI),
        birthday__gte:
          birthday_to &&
          yearBirthday(parseInt(birthday_to) || 0)
            .startOf('year')
            .format(dateFormatAPI),
      },
    ],
    getUsers,
    {
      onError: () => notify.error({ title: t('error.someThingIsWrong') }),
    },
  );

  return (
    <>
      <UsersFilter count={users?.count} />
      <Card>
        <Loader loading={isLoading} size="regular">
          <UsersTable users={users} />
        </Loader>
        <Card.Footer>
          <Pagination size={users?.total_pages || 0} />
        </Card.Footer>
      </Card>
    </>
  );
};
