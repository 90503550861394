import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSetState } from 'react-use';
import { Space } from 'ebs-design';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { Filters } from 'components/molecules';
import { defaultFilters } from 'utils';

interface FiltersProps {
  search?: string;
  total_order?: number[];
  total_order__lte?: number;
  total_order__gte?: number;
  created_at?: string[];
  created_at__lte?: string;
  created_at__gte?: string;
  last_login?: string[];
  last_login__lte?: string;
  last_login__gte?: string;
  birthday_to?: string;
  birthday_from?: string;
}

interface UsersFilterProps {
  count?: number;
}

export const UsersFilter: React.FC<UsersFilterProps> = ({ count }) => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();

  const [filters, setFilters] = useSetState<FiltersProps>({
    ...defaultFilters,
    ...params,
  });

  React.useEffect(() => {
    updateQuery(filters);
  }, [filters, updateQuery]);

  const handleFilters = (value): void => {
    if (value?.created_at) {
      const [created_at__gte, created_at__lte] = value.created_at;
      setFilters({
        created_at__gte: created_at__gte,
        created_at__lte: created_at__lte,
      });
      return;
    }

    if (value?.last_login) {
      const [last_login__gte, last_login__lte] = value.last_login;
      setFilters({
        last_login__gte: last_login__gte,
        last_login__lte: last_login__lte,
      });
      return;
    }

    if (value?.last_login === null || value?.created_at === null) {
      setFilters({
        created_at__gte: undefined,
        created_at__lte: undefined,
        last_login__gte: undefined,
        last_login__lte: undefined,
      });
      return;
    }

    setFilters(value);
  };

  return (
    <>
      <Filters className="mb-20" onChange={handleFilters}>
        <Space justify="start">
          <Space>
            <h3 className="title">{`${t(`users.users`)}${count ? ` (${count})` : ``}`}</h3>
          </Space>
          <Filters.Search field="search" value={filters.search} placeholder={t('filter.search')} />
        </Space>

        <Space justify="end">
          <p>{t('users.age')}</p>
          <Filters.Input
            type="number"
            min="1"
            field="birthday_from"
            value={filters.birthday_from}
            placeholder={t('form.from')}
            className="mw-80"
          />

          <Filters.Input
            type="number"
            max="100"
            field="birthday_to"
            value={filters.birthday_to}
            placeholder={t('form.until')}
            className="mw-80"
          />

          <p>{t('users.totalOrders')}</p>
          <Filters.Input
            type="number"
            min="1"
            field="total_order__gte"
            value={filters.total_order__gte}
            placeholder={t('form.from')}
            className="mw-80"
          />

          <Filters.Input
            type="number"
            max="100"
            field="total_order__lte"
            value={filters.total_order__lte}
            placeholder={t('form.until')}
            className="mw-80"
          />

          <p>{t('user.registrationDate')}</p>
          <Filters.DatePicker
            field="created_at"
            placeholderText={`${t('form.from')} - ${t('form.until')}`}
          />

          <p>{t('users.lastVisit')}</p>
          <Filters.DatePicker
            field="last_login"
            placeholderText={`${t('form.from')} - ${t('form.until')}`}
          />
        </Space>
      </Filters>
    </>
  );
};
