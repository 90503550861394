import * as React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, useNotify, Row, Col } from 'ebs-design';
import { useHomepageAPI } from 'api';

export interface HomepageProps {
  len_orders?: number;
  len_users?: number;
  sum_orders?: number;
}

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const notify = useNotify();

  const { getHomepage } = useHomepageAPI();

  const { data } = useQuery<HomepageProps>(['homepage', {}], getHomepage, {
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
  });

  return (
    <>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <h3>{t('dashboard.orders')}</h3>
            </Card.Header>
            <Card.Header>
              <p className="dashboard__number">{data?.len_orders}</p>
            </Card.Header>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <h3>{t('menu.users')}</h3>
            </Card.Header>
            <Card.Header>
              <p className="dashboard__number">{data?.len_users}</p>
            </Card.Header>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <h3>{`${t('dashboard.sumOrders')} ${t('ron')}`}</h3>
            </Card.Header>
            <Card.Header>
              <p className="dashboard__number">{data?.sum_orders && data?.sum_orders.toFixed(2)}</p>
            </Card.Header>
          </Card>
        </Col>
      </Row>
    </>
  );
};
