import * as React from 'react';
import cn from 'classnames';
import { Space } from 'ebs-design';
import {
  SearchFilter,
  ButtonsFilter,
  SortFilter,
  SelectFilter,
  DatePickerFilter,
  InputFilter,
} from './Inputs';
import { ContextProps, FilterComposition, FiltersProps } from './interface';

const FiltersContext = React.createContext<ContextProps>({
  onChange: () => null,
});

const Filters: React.FC<FiltersProps> & FilterComposition = ({ onChange, className, children }) => {
  return (
    <FiltersContext.Provider value={{ onChange }}>
      <Space className={cn(className)} justify="space-between" wrap>
        {children}
      </Space>
    </FiltersContext.Provider>
  );
};

Filters.Search = SearchFilter;
Filters.ButtonGroup = ButtonsFilter;
Filters.Sort = SortFilter;
Filters.Select = SelectFilter;
Filters.DatePicker = DatePickerFilter;
Filters.Input = InputFilter;

export { Filters, FiltersContext };
