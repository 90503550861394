import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import {
  Button,
  Card,
  Form,
  useForm,
  Input,
  Space,
  Row,
  Col,
  Switch,
  Upload,
  useNotify,
  Icon,
  Loader,
  Modal,
  Select,
} from 'ebs-design';
import { useBannersAPI } from 'api';
import { BannerType, Banner, SelectType } from 'types';
import { useStateHandlers } from 'hooks';
import { Remove, Pen } from 'resources/icons';
import { useUpload } from 'hooks/useUpload';
import { DropZone } from 'components/molecules/DropZone';
import { ConfirmModal } from 'components/molecules/ConfirmModal';
import { validateResponse } from 'errors/ValidationError/ValidationError';

interface BannerFormProps {
  title: string;
  type?: BannerType;
  bannerType: BannerType;
}

const formId = 'banner-form-id';

const defaultState: { type?: BannerType; delete: boolean; edit: boolean } = {
  type: undefined,
  delete: false,
  edit: false,
};

export const BannerForm: React.FC<BannerFormProps> = ({ title, type, bannerType }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const { getBanner, deleteBanner } = useBannersAPI();
  const { bannerPOSTRequest, bannerPATCHRequest } = useUpload();

  const [dateFileState, setDateFileState] = React.useState<{ file?: any }>();
  const [banner, setBanner] = React.useState<string>();
  const [isActive, setIsActive] = React.useState<boolean>(false);
  const [selectType, setSelectType] = React.useState<string>(SelectType.LINK);
  const [campaignId, setCampaignId] = React.useState<number>();
  const [state, setState] = useStateHandlers(defaultState);

  const { data, isLoading } = useQuery<Banner>(['banner', { type }], getBanner, {
    enabled: Boolean(type),
    onSuccess: (res) => {
      const { image, is_active, ...rest } = res;

      setCampaignId(rest.campaign_id);
      setSelectType(rest.campaign_id ? SelectType.ID : SelectType.LINK);
      setBanner(image);
      setIsActive(is_active);
      form.setFieldsValue(rest);
    },
  });

  const handleSubmit = (): void => {
    if (type) {
      submitRequest(bannerPATCHRequest, type, 'success.successDataChange');

      return;
    }

    submitRequest(bannerPOSTRequest, bannerType, 'success.successDataSave', true);
  };

  const submitRequest = (request, type, successMessage, is_active_true?: boolean): void =>
    request({
      ...form.getFieldsValue(),
      image: dateFileState?.file,
      is_active: is_active_true || isActive,
      type,
    })
      .then(() => {
        queryClient.invalidateQueries('banners');
        queryClient.invalidateQueries('banner');

        onSuccessResponse();

        notify.success({
          title: t(successMessage),
        });
      })
      .catch((error) => {
        validateResponse(form, error);
      });

  const handleChangeSwitch = (v: boolean): void => {
    if (type) {
      bannerPATCHRequest({ is_active: v, type })
        .then(() => {
          setIsActive(v);
          queryClient.invalidateQueries('banners');
          queryClient.invalidateQueries('banner');

          notify.success({
            title: t('success.successDataChange'),
          });
        })
        .catch(() =>
          notify.success({
            title: t('error.someThingIsWrong'),
          }),
        );
    }
  };

  const handleRequest = (dataFile: any): void => {
    setDateFileState(dataFile);

    const reader = new FileReader();

    reader.readAsDataURL(dataFile.file);

    reader.onload = () => setBanner(reader.result as string);

    if (state.edit) {
      handleCancelDelete();
    }
  };

  const handleEditBanner = (): void => {
    setState({ type: type ? type : bannerType, edit: true, delete: false });
  };

  const { mutate } = useMutation<unknown, unknown, string>(deleteBanner, {
    onError: () =>
      notify.error({
        title: t('error.someThingIsWrong'),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries('banners');
      notify.success({ title: t('success.successDataDelete') });
      onSuccessResponse();
      setIsActive(false);
      setCampaignId(undefined);
      form.resetFields();
    },
  });

  const handleRemoveBanner = (): void => {
    if (type) {
      setState({ type, delete: true, edit: false });
    }
  };

  const handleConfirmDelete = (): void => {
    if (state.type && state.delete) {
      mutate(state.type);
    }
  };

  const handleCancelDelete = (): void => setState(defaultState);

  const onSuccessResponse = (): void => {
    setBanner(undefined);
    setDateFileState({ file: undefined });
    handleCancelDelete();
  };

  const handleValuesChange = (value): void => {
    form.setFields([
      {
        name: Object.keys(value),
        errors: undefined,
      },
    ]);
  };

  const onChangeType = (e): void => setSelectType(e);

  return (
    <>
      <Form
        form={form}
        id={formId}
        onFinish={handleSubmit}
        onValuesChange={handleValuesChange}
        className="banner-form"
      >
        <Card size="large">
          <Card.Header>
            <Space justify="space-between">
              <h1 className="fs-14 fw-600">{title}</h1>
              <Switch checked={isActive} onChange={handleChangeSwitch} disabled={!type} />
            </Space>
          </Card.Header>
          <Card.Body>
            <Space align="start" className="banner-form__box-type">
              <Select
                className="banner-form__select-type"
                value={selectType}
                onChange={onChangeType}
                disabled={!!data?.id}
              >
                <Select.Options>
                  {Object.values(SelectType).map((item, key) => (
                    <Select.Options.Item value={item} key={`users-${key}`}>
                      {t(`banners.${item}`)}
                    </Select.Options.Item>
                  ))}
                </Select.Options>
              </Select>
              {selectType === SelectType.LINK ? (
                <Form.Field
                  name="link"
                  label={t('banners.link')}
                  rules={[{ required: true, message: t('banners.linkIsRequired') }]}
                >
                  <Input placeholder={t('banners.addUrlHere')} />
                </Form.Field>
              ) : (
                <Form.Field
                  name="campaign_id"
                  label={
                    <>
                      <span className="mr-5">{t('banners.id')}</span>(
                      <span className="color-text__danger">{t('banners.bannerProgrammingId')}</span>
                      )
                    </>
                  }
                  rules={[{ required: true, message: t('banners.idIsRequired') }]}
                >
                  <Input
                    type="number"
                    placeholder={t('banners.addIdHere')}
                    disabled={!!campaignId}
                  />
                </Form.Field>
              )}
            </Space>
          </Card.Body>

          <Card.Footer>
            <Row g={4}>
              <Col size={12} xl={8}>
                {isLoading ? (
                  <Space className="mt-20" justify="center" align="center">
                    <Loader.Spinner />
                  </Space>
                ) : banner ? (
                  <img
                    src={banner}
                    alt={title}
                    width={750}
                    height={200}
                    className="banner-form__banner"
                  />
                ) : (
                  <Upload accept="image/jpg, image/jpeg, image.png" customRequest={handleRequest}>
                    <DropZone />
                  </Upload>
                )}
              </Col>
              <Col size={12} xl={4}>
                <Space direction="vertical" align="start">
                  <p className="banner-form__description">{t('banners.description')}</p>
                  <Space size="small" align="start">
                    {banner && (
                      <Button
                        type="primary"
                        prefix={<Icon component={Pen} />}
                        onClick={handleEditBanner}
                      >
                        {t('buttons.edit')}
                      </Button>
                    )}
                    {data && (
                      <Button
                        type="dark"
                        prefix={<Icon component={Remove} className="banner-form__icon--delete" />}
                        onClick={handleRemoveBanner}
                      >
                        {t('buttons.delete')}
                      </Button>
                    )}
                  </Space>
                </Space>
              </Col>
              <Col size={12}>
                <Space justify="end">
                  <Button type="primary" submit disabled={!banner}>
                    {t('buttons.save')}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Form>
      {state.type && state.delete && (
        <ConfirmModal
          header={t('banners.deleteBanner')}
          onSuccess={handleConfirmDelete}
          onCancel={handleCancelDelete}
          content={t(
            state.type === BannerType.FIRST
              ? 'banners.areYouSureBannerFirst'
              : 'banners.areYouSureBannerSecond',
          )}
        />
      )}
      {state.type && state.edit && (
        <Modal
          open
          size="small"
          header={
            <h3>
              {t(
                state.type === BannerType.FIRST
                  ? 'banners.changeBannerFirst'
                  : 'banners.changeBannerSecond',
              )}
            </h3>
          }
          onClose={handleCancelDelete}
        >
          <Modal.Content>
            <Upload customRequest={handleRequest} accept="image/jpg, image/jpeg, image.png">
              <DropZone />
            </Upload>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};
