import * as React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, Space, Loader, useNotify, Row, Col, Form, Input, Button } from 'ebs-design';
import { format, dateTimeFormat } from 'libs';
import { User } from 'types';
import { useUsersAPI } from 'api';

export const UserDetails: React.FC = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const { id } = useParams<{ id: string }>();
  const { getUsersById } = useUsersAPI();

  const { data: user, isLoading } = useQuery<User>(['user', { id }], getUsersById, {
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
  });

  return (
    <>
      <Space justify="space-between" className="mb-20">
        <Space>
          <h3 className="title">{`${t(`user.userDetails`)}`}</h3>
        </Space>
        <Space>
          <Link to={'/users'}>
            <Button>{t('buttons.back')}</Button>
          </Link>
        </Space>
      </Space>
      <Loader loading={isLoading} size="regular">
        <Card>
          <Card.Header className="user-card">
            <Space align="center">
              <div className="profile__user-avatar">
                <h2 className="profile__user-title-short">
                  {[user?.first_name?.[0], user?.last_name?.[0]].join('')}
                </h2>
              </div>
              <div className="profile__upload-box">
                <h3 className="profile__user-title">
                  {t(`${user?.first_name} ${user?.last_name}`)}
                </h3>
                <p className="profile__user-subtitle">{t(`${user?.email}`)}</p>
              </div>
            </Space>
          </Card.Header>
        </Card>
        <Card className="mt-20">
          <Row className="user-details__description__row bg-white">
            <Col size={6} sm={6} md={3} className="bg-white">
              <div className="user-details__description user-details__description--heading">
                <h4 className="user-details__description__label">{t('user.registrationDate')}</h4>
                <p className="user-details__description__value">
                  {format(user?.created_at, dateTimeFormat) || '---'}
                </p>
              </div>
            </Col>
            <Col size={6} sm={6} md={3} className="bg-white">
              <div className="user-details__description user-details__description--heading">
                <h4 className="user-details__description__label">{t('user.lastLogin')}</h4>
                <p className="user-details__description__value">
                  {format(user?.last_login, dateTimeFormat) || '---'}
                </p>
              </div>
            </Col>
            <Col size={6} sm={6} md={3} className="bg-white">
              <div className="user-details__description user-details__description--heading">
                <h4 className="user-details__description__label">
                  {t('users.fullShoppingNumber')}
                </h4>
                <p className="user-details__description__value">{user?.len_orders || '---'}</p>
              </div>
            </Col>
            <Col size={6} sm={6} md={3} className="bg-white">
              <div className="user-details__description user-details__description--heading">
                <h4 className="user-details__description__label">
                  {`${t('users.shoppingSum')} ${t('ron')}`}
                </h4>
                <p className="user-details__description__value">
                  {(user?.sum_orders && parseFloat(user?.sum_orders).toFixed(2)) || '---'}
                </p>
              </div>
            </Col>
          </Row>
        </Card>

        <Card className="mt-20">
          <Form>
            <Card.Header>
              <Space align="start">
                <h3>{t('user.personalData')}</h3>
              </Space>
            </Card.Header>
            <Card.Body>
              <div className="form__box-info">
                <Row>
                  <Col size={6} className="mt-20">
                    <Form.Field name="first_name" label={t('form.first_name')}>
                      <Input placeholder={`${user?.first_name}`} disabled />
                    </Form.Field>
                  </Col>
                  <Col size={6} className="mt-20">
                    <Form.Field name="last_name" label={t('form.last_name')}>
                      <Input placeholder={`${user?.last_name}`} disabled />
                    </Form.Field>
                  </Col>
                  <Col size={12} className="mt-20">
                    <Form.Field name="email" label={t('form.email')}>
                      <Input placeholder={`${user?.email}`} disabled />
                    </Form.Field>
                  </Col>
                  <Col size={12} className="mt-20">
                    <Form.Field name="phone" label={t('form.phone')}>
                      <Input placeholder={`${user?.phone_number}`} disabled />
                    </Form.Field>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Form>
        </Card>
      </Loader>
    </>
  );
};
