import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSetState } from 'react-use';
import { Space } from 'ebs-design';
import { Filters } from 'components/molecules';
import { defaultFilters } from 'utils';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';

interface CampaignsFilterProps {
  count?: number;
}

interface FiltersProps {
  search?: string;
  status?: string;
}

export const CampaignsFilter: React.FC<CampaignsFilterProps> = ({ count }) => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();

  const [filters, setFilters] = useSetState<FiltersProps>({
    ...defaultFilters,
    ...params,
  });

  React.useEffect(() => {
    updateQuery(filters);
  }, [filters, updateQuery]);

  const handleFilters = (value): void => {
    setFilters(value);
  };

  const statusOptions = React.useMemo(
    () => [
      { label: t('filter.all'), value: '' },
      {
        label: t('filter.pending'),
        value: 'pending',
      },
      { label: t('filter.sent'), value: 'sent' },
    ],
    [t],
  );

  return (
    <>
      <Filters className="mb-20" onChange={handleFilters}>
        <Space justify="start">
          <Space>
            <h3 className="title">{`${t(`campaigns.campaigns`)}${count ? ` (${count})` : ``}`}</h3>
          </Space>
          <Filters.Search value={filters.search} field="search" placeholder={t('filter.search')} />
          <Filters.ButtonGroup options={statusOptions} field="status" value={filters.status} />
        </Space>
      </Filters>
    </>
  );
};
