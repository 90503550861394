import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSetState } from 'react-use';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Table,
  useNotify,
  Loader,
  Space,
  Button,
  Form,
  Upload,
  Icon,
  Actions,
  Label,
} from 'ebs-design';
import { Pagination } from 'components/organisms/Pagination';
import { ConfirmModal } from 'components/molecules/ConfirmModal';
import { ImageModal } from 'components/molecules/ImageModal';
import { DropZone } from 'components/molecules/DropZone';
import { FormModal } from 'components/molecules/FormModal';
import { useQueryParams } from 'hooks/useQueryParams';
import { Plus } from 'resources/icons';
import { useUpload } from 'hooks';
import { defaultFilters } from 'utils';
import { Column, Offers, Properties, Results } from 'types';
import { usePromotionsAPI } from 'api';
import { PromotionsForm } from '../PromotionsForm';
import { PromotionsFilter } from '../PromotionsFilter';
import cn from 'classnames';
interface initialStateToProps {
  offer?: Properties;
  offerImage?: number;
  showImage?: string;
}

const initialState = {
  offer: undefined,
  offerImage: undefined,
  showImage: undefined,
};

export const Promotions: React.FC = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const params = useQueryParams();
  const queryClient = useQueryClient();
  const { getPromotions, deletePromotion } = usePromotionsAPI();
  const { promotionsRequest } = useUpload();

  const [state, setState] = useSetState<initialStateToProps>(initialState);

  const { data: promotions, isLoading } = useQuery<Results<Offers>>(
    ['promotions', { ...defaultFilters, ...params }],
    getPromotions,
    {
      onError: () => notify.error({ title: t('error.someThingIsWrong') }),
    },
  );

  const handleCloseModal = (): void => {
    setState(initialState);
  };

  const handleCustomRequest = React.useCallback(
    async (options) => {
      await promotionsRequest({ id: state.offerImage, ...options });
      queryClient.invalidateQueries(['promotions']);
      notify.success({ title: t('success.successDataChange') });
      handleCloseModal();
    },
    [state.offerImage],
  );

  const deleteSplashScreenMutation = useMutation((id: number) => deletePromotion(id), {
    onError: () =>
      notify.error({
        title: t('error.someThingIsWrong'),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['promotions']);
      notify.success({ title: t('success.successDataDelete') });
      setState(initialState);
    },
  });

  const handleDelete = (): void => {
    if (typeof state.offer === 'number') {
      deleteSplashScreenMutation.mutate(state.offer);
    }
  };

  const columns: Column<Offers>[] = React.useMemo(
    () => [
      {
        title: t('promotions.image'),
        render: ({ image }) =>
          !!image && (
            <div className="promotions__image" onClick={() => setState({ showImage: image })}>
              <img className="promotions__image-small" src={image} alt={t('promotions.image')} />
            </div>
          ),
      },
      {
        title: t('promotions.promotion'),
        dataIndex: 'name',
      },
      {
        title: t('buttons.id'),
        dataIndex: 'id',
      },
      {
        title: t('campaigns.status'),
        render: ({ is_active }) => (
          <Label
            type="fill"
            className={cn('status-type', is_active ? 'active' : 'inactive')}
            text={is_active ? `${t('statuses.active')}` : `${t('statuses.inactive')}`}
          />
        ),
      },
      {
        render: ({ id, is_active }) => (
          <Space justify="end">
            <Actions placement="bottom">
              <Actions.Item onClick={() => setState({ offerImage: id })}>
                {t('buttons.addImage')}
              </Actions.Item>
              {!is_active && (
                <Actions.Item onClick={() => setState({ offer: id })}>
                  {t('buttons.delete')}
                </Actions.Item>
              )}
            </Actions>
          </Space>
        ),
      },
    ],
    [promotions],
  );

  return (
    <>
      <Space justify="space-between" className="mb-20">
        <PromotionsFilter count={promotions?.count} />
        <Button
          type="primary"
          prefix={<Icon component={Plus} />}
          onClick={() => setState({ offer: {} })}
        >
          {t('promotions.programOffers')}
        </Button>
      </Space>

      <Card>
        <Loader loading={isLoading} size="regular">
          <Table className="table-no-border" data={promotions?.results} columns={columns} />
        </Loader>
        <Card.Footer>
          <Pagination size={promotions?.total_pages || 0} />
        </Card.Footer>
      </Card>
      {state.offerImage && (
        <FormModal onCancel={handleCloseModal}>
          <Form>
            <Form.Field name="file">
              <Upload customRequest={handleCustomRequest}>
                <DropZone />
              </Upload>
            </Form.Field>
          </Form>
        </FormModal>
      )}
      {state.showImage && (
        <ImageModal header={t('promotions.image')} onClose={() => setState(initialState)}>
          <div className="modal__image-box">
            <img className="modal__image-content" src={state.showImage} />
          </div>
        </ImageModal>
      )}
      {typeof state.offer === 'object' && <PromotionsForm onClose={handleCloseModal} />}
      {typeof state.offer === 'number' && (
        <ConfirmModal
          onSuccess={handleDelete}
          onCancel={() => setState(initialState)}
          content={t('confirm.areYouSureImages')}
        />
      )}
    </>
  );
};
