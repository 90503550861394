import * as React from 'react';
import { Select, Space } from 'ebs-design';
import { Filters } from 'components/molecules';
import { useQueryUpdate } from 'hooks/useQueryParams';
import { useTranslation } from 'react-i18next';
import { Gender, LastOrder } from 'types';

interface FiltersProps {
  filters: {
    gender?: string;
    birthday_to?: string;
    birthday_from?: string;
    total_order?: number[];
    total_order__lte?: number;
    total_order__gte?: number;
    order_created?: string[];
    order_created__lte?: string;
    order_created__gte?: string;
    last_order?: number;
    product_ordered_repeatedly?: string;
  };
  setFilters: (
    patch:
      | Partial<{
          [x: string]: any;
        }>
      | ((prevState: { [x: string]: any }) => Partial<{
          [x: string]: any;
        }>),
  ) => void;
}

export const UsersTableCampaignsFilter: React.FC<FiltersProps> = ({ filters, setFilters }) => {
  const { t } = useTranslation();
  const { updateQuery } = useQueryUpdate();

  React.useEffect(() => {
    updateQuery(filters);
  }, [filters, updateQuery]);

  const handleFilters = (value): void => {
    if (value?.order_created) {
      const [order_created__gte, order_created__lte] = value.order_created;
      setFilters({
        order_created__gte: order_created__gte,
        order_created__lte: order_created__lte,
      });
      return;
    }

    if (value?.order_created === null) {
      setFilters({
        order_created__gte: undefined,
        order_created__lte: undefined,
      });
      return;
    }

    setFilters(value);
  };

  return (
    <>
      <Filters className="mb-20" onChange={handleFilters}>
        <Space>
          <Filters.Select placeholder={t('users.gender')} value={filters.gender} field="gender">
            <Select.Options>
              {Object.values(Gender).map((item, key) => (
                <Select.Options.Item value={item} key={`users-${key}`}>
                  {t(`users.${item}`)}
                </Select.Options.Item>
              ))}
            </Select.Options>
          </Filters.Select>
        </Space>
        <Space>
          <p>{t('users.age')}</p>
          <Filters.Input
            type="number"
            min="1"
            field="birthday_from"
            value={filters.birthday_from}
            placeholder={t('form.from')}
            className="mw-80"
          />

          <Filters.Input
            type="number"
            max="100"
            field="birthday_to"
            value={filters.birthday_to}
            placeholder={t('form.until')}
            className="mw-80"
          />

          <Filters.Select
            placeholder={t('users.lastOrderIn')}
            value={filters.last_order}
            field="last_order"
          >
            <Select.Options>
              {Object.values(LastOrder).map((item, key) => (
                <Select.Options.Item value={item} key={`users-${key}`}>
                  {t(`lastOrder.users-${item}`)}
                </Select.Options.Item>
              ))}
            </Select.Options>
          </Filters.Select>

          <p>{t('users.totalOrders')}</p>
          <Filters.Input
            type="number"
            min="1"
            field="total_order__gte"
            value={filters.total_order__gte}
            placeholder={t('form.from')}
            className="mw-80"
          />

          <Filters.Input
            type="number"
            max="100"
            field="total_order__lte"
            value={filters.total_order__lte}
            placeholder={t('form.until')}
            className="mw-80"
          />

          <Filters.Select
            placeholder={t('users.recurrent')}
            value={filters.product_ordered_repeatedly}
            field="product_ordered_repeatedly"
          >
            <Select.Options>
              <Select.Options.Item value="true">{t(`users.yes`)}</Select.Options.Item>
              <Select.Options.Item value="false">{t(`users.no`)}</Select.Options.Item>
            </Select.Options>
          </Filters.Select>
          <p>{t('users.createdOrder')}</p>
          <Filters.DatePicker
            field="order_created"
            placeholderText={`${t('form.from')} - ${t('form.until')}`}
          />
        </Space>
      </Filters>
    </>
  );
};
