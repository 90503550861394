import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SessionContext } from 'contexts/SessionContext';
import { AuthModal } from '../AuthModal';
import { LoginForm } from '../Form';

export const Login: React.FC = () => {
  const { t } = useTranslation();
  const { session } = React.useContext(SessionContext);
  if (session) {
    return <Redirect to="/" />;
  }

  return (
    <AuthModal title={t('form.login_in')} description={t('form.access_your_account')}>
      <LoginForm />
    </AuthModal>
  );
};
