import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Form, useForm, Input, Button, Space, useNotify, Loader, Card, Row, Col } from 'ebs-design';
import { useTranslation } from 'react-i18next';
import { InputPassword } from 'components/molecules/InputPassword';
import { SessionContext } from 'contexts/SessionContext';
import { ValidationError } from 'errors';
import { useProfileAPI } from 'api';

export const Profile: React.FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const [form] = useForm();
  const { updateProfilePassword } = useProfileAPI();
  const { user } = React.useContext(SessionContext);

  React.useEffect(() => {
    if (user) {
      form.setFieldsValue(user);
    }
  }, [user]);

  const mutation = useMutation(updateProfilePassword, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);
      } else {
        notify.error({ title: t('error.someThingIsWrong') });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('profile');
      notify.success({ title: t('success.successDataChange') });
      form.resetFields(['new_password', 'confirm_new_password', 'old_password']);
    },
  });

  const handleSubmit = (values): void => {
    mutation.mutate(values);
  };

  return (
    <>
      <Space justify="space-between" className="mt-6 mb-26">
        <Space>
          <h3 className="title">{`${t(`userProfile.profileDetails`)}`}</h3>
        </Space>
      </Space>
      <Loader loading={!user} size="regular">
        <Card>
          <Card.Header className="user-card">
            <Space align="center">
              <div className="profile__user-avatar">
                <h2 className="profile__user-title-short">
                  {[user?.first_name?.[0], user?.last_name?.[0]].join('')}
                </h2>
              </div>
              <div className="profile__upload-box">
                <h3 className="profile__user-title">
                  {t(`${user?.first_name} ${user?.last_name}`)}
                </h3>
                <p className="profile__user-subtitle">{t(`${user?.email}`)}</p>
              </div>
            </Space>
          </Card.Header>
        </Card>
        <Card className="mt-20">
          <Form form={form} onFinish={handleSubmit}>
            <Card.Header>
              <Space align="start">
                <h3>{t('user.personalData')}</h3>
              </Space>
            </Card.Header>
            <Card.Body>
              <div className="form__box-info">
                <Row>
                  <Col size={6} className="mt-20">
                    <Form.Field name="first_name" label={t('form.first_name')}>
                      <Input />
                    </Form.Field>
                  </Col>
                  <Col size={6} className="mt-20">
                    <Form.Field name="last_name" label={t('form.last_name')}>
                      <Input />
                    </Form.Field>
                  </Col>
                </Row>
              </div>
              <div className="form-divider" />
              <div className="form__box-info">
                <Space align="start">
                  <h3>{t('form.changePassword')}</h3>
                </Space>
                <Row>
                  <Col size={12} className="mt-20">
                    <Form.Field name="old_password" label={t('form.old_password')}>
                      <InputPassword />
                    </Form.Field>
                  </Col>
                  <Col size={12} className="mt-20">
                    <Form.Field name="new_password" label={t('form.new_password')}>
                      <InputPassword />
                    </Form.Field>
                  </Col>
                  <Col size={12} className="mt-20">
                    <Form.Field name="confirm_new_password" label={t('form.confirm_password')}>
                      <InputPassword />
                    </Form.Field>
                  </Col>
                </Row>
              </div>
            </Card.Body>

            <Card.Footer>
              <Space justify="end">
                <Button type="primary" onClick={() => form.submit()}>
                  {t('buttons.save')}
                </Button>
              </Space>
            </Card.Footer>
          </Form>
        </Card>
      </Loader>
    </>
  );
};
