import * as React from 'react';
import { InputSearch, Space } from 'ebs-design';
import { SearchProps } from '../interface';
import { FiltersContext } from '../Filters';

export const SearchFilter: React.FC<SearchProps> = ({
  field,
  value,
  placeholder,
  className,
  size,
}) => {
  const { onChange } = React.useContext(FiltersContext);

  const handleChange = (value: string): void => {
    onChange({ [field]: value });
  };

  return (
    <Space className={className}>
      <InputSearch
        className="filter-item"
        onSearch={handleChange}
        value={value}
        placeholder={placeholder}
        size={size}
      />
    </Space>
  );
};
