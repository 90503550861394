import * as React from 'react';
import { Layout as EBSLayout, NotifyContainer } from 'ebs-design';

const { Content } = EBSLayout;

export const PublicLayout: React.FC = ({ children }) => {
  return (
    <EBSLayout className="ebs-crm">
      <Content>
        <NotifyContainer />
        {children}
      </Content>
    </EBSLayout>
  );
};
