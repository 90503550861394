import * as React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, Table, useNotify, Loader, Space, Button } from 'ebs-design';
import { ImageModal } from 'components/molecules/ImageModal';
import { Category, Column } from 'types';
import { useCategoriesAPI } from 'api';

export const Categories: React.FC = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const { getCategories } = useCategoriesAPI();
  const [showModal, setShowModal] = React.useState<boolean | string>(false);

  const { data: categories, isLoading } = useQuery<Category[]>(['categories'], getCategories, {
    onError: () => notify.error({ title: t('error.someThingIsWrong') }),
  });

  const columns: Column<Category>[] = React.useMemo(
    () => [
      {
        title: t('promotions.image'),
        render: ({ image }) =>
          !!image && (
            <div className="promotions__image" onClick={() => setShowModal(image)}>
              <img className="promotions__image-small" src={image} alt={t('promotions.image')} />
            </div>
          ),
      },
      {
        title: t('promotions.categories'),
        dataIndex: 'title',
      },
      {
        title: t('buttons.id'),
        dataIndex: 'id',
      },
      {
        render: ({ id }) => (
          <Space justify="end">
            <Link to={`/categories/${id}`}>
              <Button type="primary">{t('buttons.details')}</Button>
            </Link>
          </Space>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Space justify="space-between" className="mt-5 mb-25">
        <Space>
          <Space>
            <h3 className="title">{`${t(`menu.categories`)}${
              categories ? ` (${categories?.length})` : ``
            }`}</h3>
          </Space>
        </Space>
      </Space>

      <Card>
        <Loader loading={isLoading} size="regular">
          <Table className="table-no-border" data={categories} columns={columns} />
        </Loader>
      </Card>
      {showModal && (
        <ImageModal header={t('promotions.image')} onClose={() => setShowModal(false)}>
          <div className="modal__image-box">
            <img className="modal__image-content" src={showModal as string} />
          </div>
        </ImageModal>
      )}
    </>
  );
};
