export interface User {
  id: number;
  email: string;
  last_login: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  address: string;
  birthday?: string;
  created_at: string;
  updated_at: string;
  len_orders: string;
  sum_orders: string;
  gender: Gender;
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export const LastOrder = [3, 6, 9, 12];
