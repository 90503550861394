import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Row, Col } from 'ebs-design';
import { Banner, BannerType } from 'types';
import { useBannersAPI } from 'api';
import { BannerForm } from 'features/banners/components';

export const Banners: React.FC = () => {
  const { t } = useTranslation();
  const { getBanners } = useBannersAPI();
  const { data } = useQuery<Banner[]>(['banners'], getBanners);

  const findType = (type: string): BannerType | undefined =>
    data?.find((i) => i.type === type)?.type;

  return (
    <Row g={3}>
      <Col size={12}>
        <h3 className="title mb-8 mt-8">{t(`menu.banners`)}</h3>
      </Col>
      <Col size={12} xxl={11}>
        <BannerForm
          type={findType(BannerType.FIRST)}
          bannerType={BannerType.FIRST}
          title={`${t('banners.banner')} 1`}
        />
      </Col>
      <Col size={12} xxl={11}>
        <BannerForm
          type={findType(BannerType.SECOND)}
          bannerType={BannerType.SECOND}
          title={`${t('banners.banner')} 2`}
        />
      </Col>
    </Row>
  );
};
