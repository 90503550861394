import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'ebs-design';

interface FormModalProps {
  onCancel: () => void;
}

export const FormModal: React.FC<FormModalProps> = ({ onCancel, children }) => {
  const { t } = useTranslation();

  return (
    <Modal open size="small" header={<h3>{t('modal.updateEntity')}</h3>} onClose={onCancel}>
      <Modal.Content>{children}</Modal.Content>
    </Modal>
  );
};
