import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthModal } from '../AuthModal';
import { SentEmailForm } from '../Form';

export const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();

  return (
    <AuthModal title={t('form.reset_password')} description={t('form.enter_your_email')}>
      <SentEmailForm />
    </AuthModal>
  );
};
