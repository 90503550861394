import * as React from 'react';
import { Button, Modal, Space } from 'ebs-design';
import { useTranslation } from 'react-i18next';

interface ConfirmModalProps {
  onSuccess: () => void;
  onCancel: () => void;
  header?: React.ReactNode;
  content?: React.ReactNode;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  onSuccess,
  onCancel,
  header,
  content,
}) => {
  const { t } = useTranslation();

  const handleCancel = (): void => onCancel();
  const handleSuccess = (): void => {
    onSuccess();
  };

  return (
    <Modal open header={header || <h3>{t('confirm.areYouSure')}</h3>} onClose={handleCancel}>
      <Modal.Content>{content || <>{t('confirm.areYouSureDescription')}</>}</Modal.Content>
      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={handleCancel}>{t('buttons.cancel')}</Button>
          <Button type="primary" onClick={handleSuccess}>
            {t('buttons.yes')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
