import * as queryString from 'querystring';
import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { getRequest } from 'utils';

export const useHomepageAPI = (): any => {
  const fetcher = useFetcher(ServiceTypes.MAIN);

  return {
    getHomepage: ({ queryKey: [, query] }) => {
      return fetcher(`/dashboard/homepage?${queryString.stringify(query)}`, getRequest);
    },
  };
};
