import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Form, Upload, useForm, Input, Button, Space, Modal, useNotify } from 'ebs-design';
import { useUpload } from 'hooks/useUpload';
import { validateResponse } from 'errors/ValidationError/ValidationError';
import { DropZone } from 'components/molecules/DropZone';
interface PromotionsFormToProps {
  onClose: () => void;
}

export const PromotionsForm: React.FC<PromotionsFormToProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [form] = useForm();
  const { promotionsInactiveRequest } = useUpload();
  const [dateFileState, setDateFileState] = React.useState<{ file: any }>();

  const handleSubmit = (): void => {
    promotionsInactiveRequest({ ...form.getFieldsValue(), image: dateFileState?.file })
      .then(() => {
        queryClient.invalidateQueries(['promotions']);
        notify.success({ title: t('success.successDataSave') });
        onClose();
      })
      .catch((error) => {
        validateResponse(form, error);
      });
  };

  const handleRequest = (dataFile: any): void => {
    setDateFileState(dataFile);
  };

  return (
    <Modal open size="small" header={<h3>{t('promotions.addOffers')}</h3>} onClose={onClose}>
      <Modal.Content>
        <Form form={form} onFinish={handleSubmit}>
          <Form.Field name="image">
            <Upload customRequest={handleRequest}>
              <DropZone />
            </Upload>
          </Form.Field>
          <Form.Field
            name="id"
            label={
              <>
                <span className="mr-5">{t('buttons.id')}</span>(
                <span className="color-text__danger">{t('modal.programmingId')}</span>)
              </>
            }
          >
            <Input type="number" isClearable />
          </Form.Field>
        </Form>
      </Modal.Content>

      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={onClose}>{t('buttons.cancel')}</Button>
          <Button type="primary" onClick={() => form.submit()}>
            {t('buttons.save')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
