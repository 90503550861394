import * as React from 'react';
import { SessionContextState } from './interface';

const defaultContextValue: SessionContextState = {
  session: null,
  user: null,
  apiConfig: null,
  removeSession: () => {
    // no op
  },
  setTokens: () => Promise.resolve(null),
};

export default React.createContext<SessionContextState>(defaultContextValue);
