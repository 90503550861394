import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Form, Upload, useForm, Button, Space, Modal, useNotify, DatePicker } from 'ebs-design';
import { dateTimeFormatInput } from 'libs';
import { useUpload } from 'hooks/useUpload';
import { validateResponse } from 'errors/ValidationError/ValidationError';
import { DropZone } from 'components/molecules/DropZone';

interface CategoryFormToProps {
  onClose: () => void;
}

export const CategoryForm: React.FC<CategoryFormToProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [form] = useForm();
  const { categoryImagesPOSTRequest } = useUpload();
  const [dateFileState, setDateFileState] = React.useState<{ file: any }>();

  const handleSubmit = (): void => {
    categoryImagesPOSTRequest({
      ...form.getFieldsValue(),
      image: dateFileState?.file,
      category: id,
    })
      .then(() => {
        queryClient.invalidateQueries(['category-images']);
        notify.success({ title: t('success.successDataSave') });
        onClose();
      })
      .catch((error) => {
        validateResponse(form, error);
      });
  };

  const handleRequest = (dataFile: any): void => {
    setDateFileState(dataFile);
  };

  return (
    <Modal open size="small" header={<h3>{t('modal.addCategoryImage')}</h3>} onClose={onClose}>
      <Modal.Content>
        <Form form={form} onFinish={handleSubmit}>
          <Form.Field name="image">
            <Upload customRequest={handleRequest}>
              <DropZone />
            </Upload>
          </Form.Field>
          <Form.Field name="start_date" label={t('modal.programmingCategoryImage')}>
            <DatePicker
              size="medium"
              showTimeSelect
              isClearable
              dateFormat={dateTimeFormatInput}
            ></DatePicker>
          </Form.Field>
        </Form>
      </Modal.Content>

      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={onClose}>{t('buttons.cancel')}</Button>
          <Button type="primary" onClick={() => form.submit()}>
            {t('buttons.save')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
