import { SelectOption } from 'types';

export const getRequest = {
  request: {
    method: 'GET',
  },
};

export const generateString = (length = 8): string => Math.random().toString(36).slice(-length);

export const generalTransformResults = (results: Record<string, any>[]): SelectOption[] => {
  return results.map(({ id, title, name, code_name }) => ({
    value: id,
    text: title || name || code_name || '---',
  }));
};

export const listTransformResults = (results: Record<string, any>[]): SelectOption[] => {
  return results.map(({ key, label }) => ({
    value: key,
    text: label || '---',
  }));
};

export const userTransformResults = (results: Record<string, any>[]): SelectOption[] => {
  return results.map(({ id, first_name, last_name }) => ({
    value: id,
    text: [first_name, last_name].filter((i) => i.length).join(' ') || '---',
  }));
};
