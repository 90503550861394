import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSetState } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Button, Card, Table, useNotify, Loader, Space, Actions, Icon, Label } from 'ebs-design';
import { Pagination } from 'components/organisms/Pagination';
import { ConfirmModal } from 'components/molecules/ConfirmModal';
import { ImageModal } from 'components/molecules/ImageModal';
import { Plus } from 'resources/icons';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { SplashScreenForm } from '../SplashScreenForm';
import { Results, Column, FilterType, SplashScreens } from 'types';
import { useSplashScreensAPI } from 'api';
import { defaultFilters } from 'utils';
import { format, dateTimeFormat } from 'libs';
import cn from 'classnames';

interface defaultStateToProps {
  visibleModal: boolean;
  visibleDeleteModal: boolean;
  id: number | null;
}

const defaultState = {
  visibleModal: false,
  visibleDeleteModal: false,
  id: null,
};

export const SplashScreenSettings: React.FC = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const params = useQueryParams();
  const queryClient = useQueryClient();
  const { updateQuery } = useQueryUpdate();
  const { getSplashScreens, deleteSplashScreen } = useSplashScreensAPI();

  const [showModal, setShowModal] = React.useState<boolean | string>(false);
  const [state, setState] = useSetState<defaultStateToProps>(defaultState);

  const { data: splashScreens, isLoading } = useQuery<Results<SplashScreens>>(
    ['splash-screens', { ...defaultFilters, ...params }],
    getSplashScreens,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const deleteSplashScreenMutation = useMutation((id: number) => deleteSplashScreen(id), {
    onError: () =>
      notify.error({
        title: t('error.someThingIsWrong'),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['splash-screens']);
      notify.success({ title: t('success.successDataDelete') });
      setState({ visibleDeleteModal: false, id: null });
    },
  });

  const handleDelete = (): void => {
    if (typeof state.id === 'number') {
      deleteSplashScreenMutation.mutate(state.id);
    }
  };

  const handleSorting = (type: FilterType, field: string): FilterType => {
    updateQuery({ ...params, ordering: type ? `${type === 'desc' ? '-' : ''}${field}` : type });
    return type;
  };

  const columns: Column<SplashScreens>[] = React.useMemo(
    () => [
      {
        title: t('promotions.image'),
        render: ({ image }) =>
          !!image && (
            <div className="promotions__image" onClick={() => setShowModal(image)}>
              <img className="promotions__image-medium" src={image} alt={t('promotions.image')} />
            </div>
          ),
      },
      {
        title: t('form.categoryName'),
        onFilter: (type) => handleSorting(type, 'title'),
        dataIndex: 'title',
      },
      {
        title: t('settingsDashboard.displaySeconds'),
        onFilter: (type) => handleSorting(type, 'display_seconds'),
        render: ({ display_seconds }) => `${display_seconds} ${t('settingsDashboard.seconds')}`,
      },
      {
        title: t('user.publishingDate'),
        onFilter: (type) => handleSorting(type, 'start_date'),
        render: ({ start_date }) => format(start_date, dateTimeFormat),
      },
      {
        title: t('campaigns.status'),
        onFilter: (type) => handleSorting(type, 'is_active'),
        render: ({ is_active }) => (
          <Label
            type="fill"
            className={cn('status-type', is_active ? 'active' : 'inactive')}
            text={is_active ? `${t('statuses.active')}` : `${t('statuses.inactive')}`}
          />
        ),
      },
      {
        render: ({ id }) => (
          <Space justify="end">
            <Actions placement="bottom">
              <Actions.Item onClick={() => setState({ visibleModal: true, id })}>
                {t('buttons.edit')}
              </Actions.Item>
              <Actions.Item onClick={() => setState({ visibleDeleteModal: true, id })}>
                {t('buttons.delete')}
              </Actions.Item>
            </Actions>
          </Space>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Space justify="space-between" className="mb-20">
        <Space>
          <h3 className="title">{`${t(`settingsDashboard.screensSplash`)}${
            splashScreens?.count ? ` (${splashScreens.count})` : ``
          }`}</h3>
        </Space>
        <Space>
          <Button
            type="primary"
            prefix={<Icon component={Plus} />}
            onClick={() => setState({ visibleModal: true })}
          >
            {t('buttons.add')}
          </Button>
        </Space>
      </Space>

      <Card>
        <Loader loading={isLoading} size="regular">
          <Table className="table-no-border" data={splashScreens?.results} columns={columns} />
        </Loader>
        <Card.Footer>
          <Pagination size={splashScreens?.total_pages || 0} />
        </Card.Footer>
      </Card>

      {showModal && (
        <ImageModal header={t('promotions.image')} onClose={() => setShowModal(false)}>
          <div className="modal__image-box">
            <img className="modal__image-content" src={showModal as string} />
          </div>
        </ImageModal>
      )}

      {state.visibleModal && (
        <SplashScreenForm onClose={() => setState(defaultState)} id={state.id} />
      )}

      {!!state.visibleDeleteModal && (
        <ConfirmModal
          onSuccess={handleDelete}
          onCancel={() => setState(defaultState)}
          content={t('confirm.areYouSureImages')}
        />
      )}
    </>
  );
};
