import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Logout, UserAlone } from 'resources/icons';
import { accessTokenKey } from 'contexts/SessionContext/SessionProvider';

export const UserProfileTooltip: React.FC = () => {
  const { t } = useTranslation();

  const handleLogOut = (): void => {
    window.localStorage.removeItem(accessTokenKey);
    window.location.href = '/login';
  };

  return (
    <div className="user-profile-tooltip">
      <div className="user-profile-tooltip__main">
        <div className="user-profile-tooltip__header">{t('userProfile.profileManagement')}</div>
        <div className="user-profile-tooltip__profile-management">
          <Link to="/profile" className="user-profile-tooltip__profile-management__item">
            <UserAlone />
            {t('userProfile.settings')}
          </Link>
          <Link
            to="/"
            className="user-profile-tooltip__profile-management__item"
            onClick={handleLogOut}
          >
            <Logout />
            {t('userProfile.logout')}
          </Link>
        </div>
      </div>
    </div>
  );
};
