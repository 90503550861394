import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Icon,
  Card,
  Table,
  useNotify,
  Loader,
  Space,
  AvatarInline,
  Actions,
} from 'ebs-design';
import { Pagination } from 'components/organisms/Pagination';
import { ConfirmModal } from 'components/molecules/ConfirmModal';
import { UserRole } from 'hooks/useFetcher/interfaces';
import { SessionContext } from 'contexts/SessionContext';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { ForbiddenError } from 'components/atoms';
import { Plus } from 'resources/icons';
import { Results, Column, FilterType, AdminUser } from 'types';
import { format, dateTimeFormat } from 'libs';
import { defaultFilters } from 'utils';
import { useAdminUsersAPI } from 'api';

export const AdminUsers: React.FC = () => {
  const { user } = React.useContext(SessionContext);

  if (user?.role !== UserRole.SUPER_ADMIN) {
    return <ForbiddenError />;
  } else {
    const { t } = useTranslation();
    const params = useQueryParams();
    const queryClient = useQueryClient();
    const notify = useNotify();
    const { updateQuery } = useQueryUpdate();
    const { getAdminUsers, deleteAdminUser } = useAdminUsersAPI();

    const [itemId, setItemId] = React.useState();

    const { data: adminUsers, isLoading } = useQuery<Results<AdminUser>>(
      ['admin-users', { ...defaultFilters, ...params }],
      getAdminUsers,
      {
        onError: () => notify.error({ title: t('error.someThingIsWrong') }),
      },
    );

    const deleteAdminUsersMutation = useMutation(deleteAdminUser, {
      onError: () => notify.error({ title: t('error.someThingIsWrong') }),
      onSuccess: () => {
        queryClient.invalidateQueries(['admin-users']);
        notify.success({ title: t('success.successDataDelete') });
        setItemId(undefined);
      },
    });

    const handleDelete = (): void => {
      if (itemId) {
        deleteAdminUsersMutation.mutate(itemId);
      }
    };

    const handleCancelDelete = (): void => {
      setItemId(undefined);
    };

    const handleSorting = (type: FilterType, field: string): FilterType => {
      updateQuery({ ...params, ordering: type ? `${type === 'desc' ? '-' : ''}${field}` : type });
      return type;
    };

    const columns: Column<AdminUser>[] = React.useMemo(
      () => [
        {
          title: t('users.adminUserName'),
          onFilter: (type) => handleSorting(type, 'email'),
          render: ({ first_name, last_name, email }): React.ReactNode => (
            <AvatarInline
              circle
              alt={[first_name, last_name].join(' ') || '---'}
              shortAlt={[first_name?.[0], last_name?.[0]].join('')}
              description={email}
            />
          ),
        },
        {
          title: t('buttons.id'),
          onFilter: (type) => handleSorting(type, 'id'),
          render: ({ id }) => id,
        },
        {
          title: t('user.lastLogin'),
          onFilter: (type) => handleSorting(type, 'updated_at'),
          render: ({ updated_at }) => format(updated_at, dateTimeFormat),
        },
        {
          title: t('user.registrationDate'),
          onFilter: (type) => handleSorting(type, 'created_at'),
          render: ({ created_at }) => format(created_at, dateTimeFormat),
        },
        {
          render: ({ id }) => (
            <Space justify="end">
              <Actions placement="bottom">
                <Link to={`/administrators/${id}`}>
                  <Actions.Item>{t('buttons.edit')}</Actions.Item>
                </Link>
                <Actions.Item onClick={() => setItemId(id)}>{t('buttons.delete')}</Actions.Item>
              </Actions>
            </Space>
          ),
        },
      ],
      [],
    );

    return (
      <>
        <Space justify="space-between" className="mb-20">
          <Space>
            <h3 className="title">{`${t(`menu.administrators`)}${
              adminUsers?.count ? ` (${adminUsers.count})` : ``
            }`}</h3>
          </Space>
          <Space>
            <Link to="/administrators/create">
              <Button type="primary" prefix={<Icon component={Plus} />}>
                {t('buttons.add')}
              </Button>
            </Link>
          </Space>
        </Space>

        <Card>
          <Loader loading={isLoading} size="regular">
            <Table className="table-no-border" data={adminUsers?.results} columns={columns} />
          </Loader>
          <Card.Footer>
            <Pagination size={adminUsers?.total_pages || 0} />
          </Card.Footer>
        </Card>

        {!!itemId && <ConfirmModal onSuccess={handleDelete} onCancel={handleCancelDelete} />}
      </>
    );
  }
};
