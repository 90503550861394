import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Form, Space, Row, Col, useForm, useNotify } from 'ebs-design';
import { InputPassword } from 'components/molecules/InputPassword';
import { SessionContext } from 'contexts/SessionContext';
import { Tokens } from 'hooks/useFetcher/interfaces';
import { ValidationError } from 'errors';
import { useMutation } from 'react-query';
import { useLoginAPI } from 'api';
import { Password } from 'types';

export const PasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const notify = useNotify();
  const history = useHistory();
  const { resetPassword } = useLoginAPI();
  const { token } = useParams<{ token: string }>();

  const { setTokens } = React.useContext(SessionContext);

  const mutation = useMutation<Tokens, unknown, Password>(resetPassword, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);
      } else {
        notify.error({ title: t('error.loginFail') });
      }
    },
    onSuccess: (response: Tokens) => {
      setTokens(response);
      notify.success({ title: t('success.successDataChange') });
      history.push('/login');
    },
  });

  const handleSubmit = (): void => {
    mutation.mutate({ token, ...form.getFieldsValue() });
  };

  return (
    <Form
      form={form}
      type="horizontal"
      onFinish={handleSubmit}
      controlOptions={{ col: { size: 9 } }}
      labelOptions={{ col: { size: 3 } }}
    >
      <Form.Field name="new_password" label={t('form.new_password')}>
        <InputPassword />
      </Form.Field>
      <Form.Field name="confirm_new_password" label={t('form.confirm_password')}>
        <InputPassword />
      </Form.Field>
      <Row className="mt-15">
        <Col size={3} />

        <Col size={9}>
          <div className="authentication-modal-form-actions">
            <Space justify="space-between">
              <Button type="primary" submit buttonClass="uppercase" className="full-width">
                {t('buttons.reset')}
              </Button>
            </Space>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
