import React from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { NotifyProvider } from 'ebs-design';
import Layout from 'components/template/Layout';
import PublicLayout from 'components/template/PublicLayout';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { Bootstrap } from 'contexts/SessionContext';
import { publicRoutes, routes } from 'utils';

import * as ro from 'locales/ro';
import { NotFound } from 'errors';

const mainConfig = {
  baseUrl: process.env.REACT_APP_BASE_API || '',
};

const config = {
  [ServiceTypes.MAIN]: mainConfig,
};

//set multi-language
i18n.use(initReactI18next).init({
  resources: { ro },
  lng: localStorage.getItem('lang') || 'ro',
  fallbackLng: ['ro'],

  interpolation: {
    escapeValue: false,
  },
});

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Bootstrap config={config}>
        <NotifyProvider>
          <Switch>
            <Route path={['/login', '/forgot-password', '/reset-password']}>
              <PublicLayout>
                <Switch>
                  {publicRoutes.map(({ Component, ...item }) => (
                    <Route key={item.name} path={item.path} exact={item.exact}>
                      <Component />
                    </Route>
                  ))}
                </Switch>
              </PublicLayout>
            </Route>
            <Route path="/">
              <Layout>
                <Switch>
                  {routes.map(({ Component, ...item }) => (
                    <Route key={item.name} path={item.path} exact={item.exact}>
                      <Component />
                    </Route>
                  ))}
                  <Route component={NotFound} />
                </Switch>
              </Layout>
            </Route>
          </Switch>
        </NotifyProvider>
      </Bootstrap>
    </BrowserRouter>
  );
};
