import { Route } from 'types';
import { Dashboard } from 'features/dashboard/pages';
import { Promotions } from 'features/promotions/pages';
import { Users, UserDetails } from 'features/users/pages';
import { AdminUsers, AdminUsersForm } from 'features/admin-users/pages';
import { GeneralSettings, SplashScreenSettings } from 'features/settings/pages';
import { Login, ForgotPassword, ResetPassword } from 'features/public/pages';
import { FormCampaigns, Campaigns, CampaignDetails } from 'features/campaigns/pages';
import { Profile } from 'features/profile/pages';
import { Categories, CategoriesDetails } from 'features/categories/pages';
import { Banners } from 'features/banners/pages';

export const routes: Route[] = [
  { name: 'Dashboard', path: '/', exact: true, Component: Dashboard },
  { name: 'Users', path: '/users', exact: true, Component: Users },
  { name: 'UserDetails', path: `/users/:id`, Component: UserDetails },
  { name: 'Deals', path: '/promotions', exact: true, Component: Promotions },
  { name: 'Categories', path: '/categories', exact: true, Component: Categories },
  {
    name: 'CategoriesDetails',
    path: '/categories/:id',
    exact: true,
    Component: CategoriesDetails,
  },
  { name: 'Campaigns', path: '/campaigns', exact: true, Component: Campaigns },
  { name: 'FormCampaigns', path: '/campaigns/:id', Component: FormCampaigns },
  { name: 'Campaign', path: '/campaign/:id', Component: CampaignDetails },
  { name: 'AdminUsers', path: '/administrators', exact: true, Component: AdminUsers },
  { name: 'AdminUsersForm', path: '/administrators/:id', Component: AdminUsersForm },
  { name: 'GeneralSettings', path: '/settings/general', exact: true, Component: GeneralSettings },
  {
    name: 'SplashScreenSettings',
    path: '/settings/splash-screen',
    exact: true,
    Component: SplashScreenSettings,
  },
  { name: 'Banners', path: '/banners', exact: true, Component: Banners },
  { name: 'Profile', path: '/profile', exact: true, Component: Profile },
];

export const publicRoutes: Route[] = [
  { name: 'ForgotPassword', path: '/forgot-password', exact: true, Component: ForgotPassword },
  { name: 'ResetPassword', path: '/reset-password/:token', exact: true, Component: ResetPassword },
  { name: 'Login', path: '/login', exact: true, Component: Login },
];
