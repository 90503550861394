export interface Banner {
  id?: number;
  type: BannerType;
  image?: string;
  link?: string;
  campaign_id?: number;
  is_active: boolean;
}

export enum BannerType {
  FIRST = 'first',
  SECOND = 'second',
}

export enum SelectType {
  LINK = 'link',
  ID = 'id',
}
