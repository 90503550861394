import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';

export const useActiveLocation = (): any => {
  const location = useLocation();

  const isCurrentURL = (url: string | string[]): boolean => {
    return !!matchPath(location.pathname, {
      path: url,
      exact: true,
    });
  };

  return { isCurrentURL };
};
