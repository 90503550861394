import * as React from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, Space, Loader, useNotify, Row, Col, Form, Input, useForm, Button } from 'ebs-design';
import { InputPassword } from 'components/molecules/InputPassword';
import { AdminUser } from 'types';
import { useAdminUsersAPI } from 'api';
import { ValidationError } from 'errors';

export const AdminUsersForm: React.FC = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const [form] = useForm();
  const { getAdminUserById, createAdminUser, updateAdminUser } = useAdminUsersAPI();

  const { data: adminUser, isLoading } = useQuery<AdminUser>(
    ['admin-user', { id }],
    getAdminUserById,
    {
      enabled: Boolean(id) && id !== 'create',
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  React.useEffect(() => {
    if (adminUser) {
      form.setFieldsValue(adminUser);
    }
  }, [adminUser]);

  const mutation = useMutation(id === 'create' ? createAdminUser : updateAdminUser, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);
        notify.error({ title: t('error.someThingIsWrong') });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['admin-user']);
      notify.success({ title: t('success.successDataSave') });
      history.push('/administrators');
    },
  });

  const handleSubmit = (values): void => {
    mutation.mutate({
      ...values,
      // ...(id !== 'create' && { id }),
    });
  };

  return (
    <>
      <Space justify="space-between" className="mt-5 mb-25">
        <Space>
          <h3 className="title">
            {`${id === 'create' ? t(`user.adminCreate`) : t(`user.adminEdit`)}`}
          </h3>
        </Space>
      </Space>
      <Loader loading={isLoading} size="regular">
        <Card>
          <Form form={form} onFinish={handleSubmit}>
            <Card.Header>
              <Space align="start">
                <h3>{t('user.personalData')}</h3>
              </Space>
            </Card.Header>
            <Card.Body>
              <div className="form__box-info">
                <Row>
                  <Col size={6} className="mt-20">
                    <Form.Field name="first_name" label={t('form.first_name')}>
                      <Input />
                    </Form.Field>
                  </Col>
                  <Col size={6} className="mt-20">
                    <Form.Field name="last_name" label={t('form.last_name')}>
                      <Input />
                    </Form.Field>
                  </Col>
                  <Col size={12} className="mt-20">
                    <Form.Field name="email" label={t('form.email')}>
                      <Input />
                    </Form.Field>
                  </Col>
                  {id === 'create' && (
                    <Col size={12} className="mt-20">
                      <Form.Field name="password" label={t('form.password')}>
                        <InputPassword />
                      </Form.Field>
                    </Col>
                  )}
                </Row>
              </div>
            </Card.Body>
            <Card.Footer>
              <Space justify="end">
                <Link to="/administrators">
                  <Button>{t('buttons.cancel')}</Button>
                </Link>
                <Button type="primary" submit>
                  {t('buttons.save')}
                </Button>
              </Space>
            </Card.Footer>
          </Form>
        </Card>
      </Loader>
    </>
  );
};
