import * as React from 'react';
import { Input, Icon } from 'ebs-design';
import { InputProps } from 'ebs-design/dist/components/atoms/Input/Input';

export const InputPassword: React.FC<InputProps> = ({ onChange, value, ...props }) => {
  const [type, setType] = React.useState<undefined | 'password'>('password');

  const onToggleType = (): void => setType((s) => (s === undefined ? 'password' : undefined));

  return (
    <Input
      {...props}
      onClickSuffix={onToggleType}
      suffix={<Icon type="eye" className={`cursor${type === undefined ? ' active' : ''}`} />}
      value={value}
      onChange={onChange}
      type={type}
    />
  );
};
