import * as queryString from 'querystring';
import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { getRequest } from 'utils';

export const useCategoriesAPI = (): any => {
  const fetcher = useFetcher(ServiceTypes.MAIN);

  return {
    getCategories: ({ queryKey: [, query] }) => {
      return fetcher(`/dashboard/categories?${queryString.stringify(query)}`, getRequest);
    },
    getImagesByCategoryId: ({ queryKey: [, { id }] }) => {
      return fetcher(`/dashboard/images-by-category/${id}`, getRequest);
    },
    deleteCategoryImage: (id: number) => {
      return fetcher(`/dashboard/category-images/${id}`, {
        request: {
          method: 'DELETE',
        },
      });
    },
  };
};
