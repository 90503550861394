import * as React from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import SessionProvider from 'contexts/SessionContext/SessionProvider';

import { BootstrapProps } from './interface';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const Bootstrap: React.FC<BootstrapProps> = ({ config, children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <SessionProvider apiConfig={config}>{children}</SessionProvider>
    </QueryClientProvider>
  );
};
