import * as React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Card, Col, Icon, Row, Space, useNotify, Button } from 'ebs-design';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Campaign } from 'types';
import { useCampaignsAPI } from 'api';
import { Calendar } from 'resources/icons';
import { dateTimeFormat, format } from 'libs';

export const CampaignDetails: React.FC = () => {
  const { getCampaignsById } = useCampaignsAPI();
  const { t } = useTranslation();
  const notify = useNotify();
  const { id } = useParams<{ id: string }>();

  const { data: campaign } = useQuery<Campaign>(['campaign', { id }], getCampaignsById, {
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
  });

  return (
    <div className="container-campaing">
      <Space justify="space-between">
        <Space>
          <h3 className="title">{campaign?.subject}</h3>
        </Space>
        <Space>
          <Link to={'/campaigns'}>
            <Button>{t('buttons.back')}</Button>
          </Link>
        </Space>
      </Space>
      <Card className="mt-20">
        <Space className="message">{campaign?.message}</Space>
      </Card>
      <Space className="mt-30">
        <Card className="receivers">
          <h4 className="pb-10 fs-14">{t('campaigns.receivers')}</h4>
          <Space>
            <Icon type="users"></Icon>
            <p className="fs-18">{campaign?.total_receivers}</p>
          </Space>
        </Card>
        <Card className="date">
          <h4 className="pb-10 fs-14">{t('campaigns.executionDate')}</h4>
          <Space>
            <Icon component={Calendar}></Icon>
            <p className="fs-18">{format(campaign?.start_date, dateTimeFormat) || '---'}</p>
          </Space>
        </Card>
      </Space>
      <Card className="mt-30">
        <Row>
          <Col size={6} sm={6} md={4} className="total-details">
            <div className="my-30 px-30">
              <h4 className="pb-5 fs-14">{t('campaigns.totalSent')}</h4>
              <p className="fs-18 fw-600">{campaign?.total_sent}</p>
            </div>
          </Col>
          <Col size={6} sm={6} md={4} className="total-details">
            <div className="my-30 px-30">
              <h4 className="pb-5 fs-14">{t('campaigns.totalViews')}</h4>
              <p className="fs-18 fw-600">{campaign?.total_views}</p>
            </div>
          </Col>
          <Col size={6} sm={6} md={4} className="total-details">
            <div className="my-30 px-30">
              <h4 className="pb-5 fs-14">{t('campaigns.totalPending')}</h4>
              <p className="fs-18 fw-600">{campaign?.total_pending}</p>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
