import * as queryString from 'querystring';
import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { getRequest } from 'utils';

export const useBannersAPI = (): any => {
  const fetcher = useFetcher(ServiceTypes.MAIN);

  return {
    getBanners: ({ queryKey: [, query] }) => {
      return fetcher(`/dashboard/banners?${queryString.stringify(query)}`, getRequest);
    },
    getBanner: ({ queryKey: [, { type }] }) => fetcher(`/dashboard/banners/${type}`, getRequest),

    deleteBanner: (type: string) => {
      return fetcher(`/dashboard/banners/${type}`, {
        request: {
          method: 'DELETE',
        },
      });
    },
  };
};
