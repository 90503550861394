import * as queryString from 'querystring';
import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { Results, User } from 'types';

import { getRequest } from 'utils';

export const useUsersAPI = (): any => {
  const fetcher = useFetcher(ServiceTypes.MAIN);

  return {
    getUsers: ({ queryKey: [, query] }) => {
      return fetcher<Results<User>>(`/dashboard/users?${queryString.stringify(query)}`, getRequest);
    },
    getUsersById: ({ queryKey: [, { id }] }) => fetcher(`/dashboard/users/${id}`, getRequest),
  };
};
