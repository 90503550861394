import * as queryString from 'querystring';
import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { getRequest } from 'utils';

export const usePromotionsAPI = (): any => {
  const fetcher = useFetcher(ServiceTypes.MAIN);

  return {
    getPromotions: ({ queryKey: [, query] }) => {
      return fetcher(`/dashboard/promotions?${queryString.stringify(query)}`, getRequest);
    },
    deletePromotion: (id: number) => {
      return fetcher(`/dashboard/inactive-promotions/${id}`, {
        request: {
          method: 'DELETE',
        },
      });
    },
  };
};
