import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Administrator,
  Dashboard,
  Deals,
  Settings,
  Users,
  Company,
  Categories,
  Banner,
} from 'resources/icons/sidebar';
import { useActiveLocation } from 'hooks';
import { UserRole } from 'hooks/useFetcher/interfaces';
import { SessionContext } from 'contexts/SessionContext';

export interface Module {
  iconType?: string;
  iconComponent?: React.FC;
  label: string;
  route: string;
  params?: { [key: string]: any };
  active: boolean;
  index?: boolean;
  visible?: boolean;
  children?: Modules;
}

interface Modules {
  [key: string]: Module;
}

export const useModules = (): { modules: Modules } => {
  const { t } = useTranslation();
  const { isCurrentURL } = useActiveLocation();
  const { user } = React.useContext(SessionContext);

  const modules: Modules = {
    dashboard: {
      iconComponent: Dashboard,
      label: t('menu.dashboard'),
      route: '/',
      params: {},
      active: isCurrentURL('/'),
      index: true,
    },
    users: {
      iconComponent: Users,
      label: t('menu.users'),
      route: '/users',
      params: {},
      active: isCurrentURL(['/users', '/users/:id']),
      index: true,
    },
    deals: {
      iconComponent: Deals,
      label: t('menu.deals'),
      route: '/promotions',
      params: {},
      active: isCurrentURL('/promotions'),
      index: true,
    },
    categories: {
      iconComponent: Categories,
      label: t('menu.categories'),
      route: '/categories',
      params: {},
      active: isCurrentURL(['/categories', '/categories/:id']),
      index: true,
    },
    messages: {
      iconComponent: Company,
      label: t('menu.campaigns'),
      route: '/campaigns',
      params: {},
      active: isCurrentURL(['/campaigns', '/campaigns/:id', '/campaign/:id']),
      index: true,
    },
    ...(user?.role === UserRole.SUPER_ADMIN && {
      administrators: {
        iconComponent: Administrator,
        label: t('menu.administrators'),
        route: '/administrators',
        params: {},
        active: isCurrentURL(['/administrators', '/administrators/:id']),
        index: true,
      },
    }),
    settings: {
      iconComponent: Settings,
      label: t('menu.settings'),
      route: '/settings/general',
      params: {},
      active: isCurrentURL('/settings/general'),
      index: true,
      children: {
        general: {
          label: t('settingsDashboard.general'),
          route: '/settings/general',
          params: {},
          active: isCurrentURL('/settings/general'),
          index: true,
        },
        screenSplash: {
          label: t('settingsDashboard.screenSplash'),
          route: '/settings/splash-screen',
          params: {},
          active: isCurrentURL('/settings/splash-screen'),
          index: true,
        },
      },
    },
    banners: {
      iconComponent: Banner,
      label: t('menu.banners'),
      route: '/banners',
      params: {},
      active: isCurrentURL('/banners'),
      index: true,
    },
  };

  return {
    modules,
  };
};
