import * as React from 'react';
import { SortBy, Space } from 'ebs-design';
import { SortProps } from '../interface';
import { FiltersContext } from '../Filters';

export const SortFilter: React.FC<SortProps> = ({
  field,
  options,
  placeholder,
  sortByTitle,
  value,
  className,
}) => {
  const { onChange } = React.useContext(FiltersContext);
  const handleChange = (value: string): void => {
    onChange({ [field]: value });
  };

  return (
    <Space className={className}>
      <SortBy
        value={value || ''}
        title={placeholder}
        sortByTitle={sortByTitle}
        options={options}
        onChange={handleChange}
      />
    </Space>
  );
};
