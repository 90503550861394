import { OptionValue } from 'ebs-design/dist/components/molecules/Select/interfaces';
import { Properties } from 'types';
import { dateFormatAPI, today } from 'libs';

export const defaultFilters: Properties = {
  page: 1,
  page_size: 10,
};

export const defaultNonPagination: Properties = {
  page: 1,
  page_size: 100,
};

export const formatSelectValueToNumber = (
  value?: OptionValue | OptionValue[],
): number | number[] | undefined => {
  if (!value) return;
  if (Array.isArray(value)) {
    return value.map((item) => (typeof item === 'number' ? item : parseInt(item)));
  }
  return typeof value === 'number' ? value : parseInt(value);
};

export const getPeriodByName = (name: string): (string | undefined)[] => {
  switch (name) {
    case 'today':
      return [today.format(dateFormatAPI), today.format(dateFormatAPI)];
    case 'week':
      return [
        today.startOf('week').format(dateFormatAPI),
        today.endOf('week').format(dateFormatAPI),
      ];
    case 'month':
      return [
        today.startOf('month').format(dateFormatAPI),
        today.endOf('month').format(dateFormatAPI),
      ];
    case 'quarter':
      return [
        today
          .set('month', Math.ceil(today.month() / 3) - 1)
          .startOf('month')
          .format(dateFormatAPI),
        today
          .set('month', Math.ceil(today.month() / 3) + 2)
          .endOf('month')
          .format(dateFormatAPI),
      ];
    case 'year':
      return [
        today.startOf('year').format(dateFormatAPI),
        today.endOf('year').format(dateFormatAPI),
      ];

    case 'yesterday':
      return [
        today.subtract(1, 'day').format(dateFormatAPI),
        today.subtract(1, 'day').format(dateFormatAPI),
      ];
    case 'previous_week':
      return [
        today.subtract(1, 'week').startOf('week').format(dateFormatAPI),
        today.subtract(1, 'week').endOf('week').format(dateFormatAPI),
      ];
    case 'previous_month':
      return [
        today.subtract(1, 'month').startOf('week').format(dateFormatAPI),
        today.subtract(1, 'month').endOf('week').format(dateFormatAPI),
      ];
    case 'previous_year':
      return [
        today.subtract(1, 'year').startOf('week').format(dateFormatAPI),
        today.subtract(1, 'year').endOf('week').format(dateFormatAPI),
      ];

    default:
      return [undefined, undefined];
  }
};

export const convertToDate = (date: string | undefined): Date => {
  if (!date) {
    return new Date(today.toString());
  }
  return new Date(date);
};
