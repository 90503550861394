import * as queryString from 'querystring';
import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { getRequest } from 'utils';

export const useGeneralSettingsAPI = (): any => {
  const fetcher = useFetcher(ServiceTypes.MAIN);

  return {
    getGeneralSettings: ({ queryKey: [, query] }) => {
      return fetcher(`/dashboard/settings?${queryString.stringify(query)}`, getRequest);
    },
    createGeneralSettings: (data) => {
      return fetcher(`/dashboard/settings`, {
        data,
        request: {
          method: 'POST',
        },
      });
    },
  };
};
