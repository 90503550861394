import * as React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Button, Card, Form, useForm, Input, Space, useNotify, Loader } from 'ebs-design';
import { Settings } from 'types';
import { useGeneralSettingsAPI } from 'api';

export const GeneralSettings: React.FC = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { getGeneralSettings, createGeneralSettings } = useGeneralSettingsAPI();

  const { data, isLoading } = useQuery<Settings>('settings', getGeneralSettings, {
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
  });

  React.useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  const createSettingMutation = useMutation(createGeneralSettings, {
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
    onSuccess: () => {
      queryClient.invalidateQueries('settings');
      notify.success({ title: t('success.successDataChange') });
    },
  });

  const handleSubmit = (): void => {
    createSettingMutation.mutate(form.getFieldsValue());
  };

  return (
    <Card className="mt-15">
      <Form form={form} onFinish={handleSubmit}>
        <Card.Header>
          <Space align="start">
            <h3>{t('user.emailChange')}</h3>
          </Space>
        </Card.Header>
        <Card.Body>
          <Loader loading={isLoading} size="regular">
            <div className="form__box-email">
              <Space align="start">
                <h3 className="form__box-title">{t('form.emailEnter')}</h3>
              </Space>
              <Form.Field name="email">
                <Input type="email" />
              </Form.Field>
            </div>
          </Loader>
        </Card.Body>
        <Card.Footer>
          <Space justify="end">
            <Button type="primary" onClick={() => form.submit()}>
              {t('buttons.save')}
            </Button>
          </Space>
        </Card.Footer>
      </Form>
    </Card>
  );
};
