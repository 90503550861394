import * as React from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Loader,
  Radio,
  Space,
  Textarea,
  useForm,
  useNotify,
} from 'ebs-design';
import { useSetState } from 'react-use';
import { useTranslation } from 'react-i18next';
import { UsersTableCampaigns } from '../UsersTableCampaigns';
import { defaultFilters } from 'utils';
import { Pagination } from 'components/organisms/Pagination';
import { useQueryParams } from 'hooks/index';
import { UsersTableCampaignsFilter } from '../Filters';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useCampaignsAPI, useUsersAPI } from 'api';
import {
  dateFormatAPI,
  yearBirthday,
  dateTimeFormatInput,
  dateTimeFormat,
  format,
  dateTimeFormatAPI,
} from 'libs';
import { ValidationError } from 'errors';
import { Campaign, Results, User } from 'types';
import { useQueryUpdate } from 'hooks/useQueryParams';

interface CampaignsType {
  [key: string]: string;
}

export const FormCampaigns: React.FC = () => {
  const { t } = useTranslation();
  const notify = useNotify();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [form] = useForm();
  const { updateQuery } = useQueryUpdate();
  const { id } = useParams<{ id: string }>();

  const [radioValue, setRadioValue] = React.useState('registered');

  const { getUsers } = useUsersAPI();
  const { getCampaignsType, getCampaignsById, createCampaign, updateCampaign } = useCampaignsAPI();
  const { birthday_from, birthday_to, ...params } = useQueryParams();

  const [selectUser, setSelectUser] = React.useState([]);
  const [filters, setFilters] = useSetState({
    ...defaultFilters,
    ...params,
  });

  const { data: users, isLoading } = useQuery<Results<User>>(
    [
      'users',
      {
        ...defaultFilters,
        ...params,
        birthday__lte:
          birthday_from &&
          yearBirthday(parseInt(birthday_from) || 0)
            .endOf('year')
            .format(dateFormatAPI),
        birthday__gte:
          birthday_to &&
          yearBirthday(parseInt(birthday_to) || 0)
            .startOf('year')
            .format(dateFormatAPI),
      },
    ],
    getUsers,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const { data: editCampaign } = useQuery<Campaign>(['campaign', { id }], getCampaignsById, {
    enabled: Boolean(id) && id !== 'create',
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
  });

  const { data: campaignsType } = useQuery<CampaignsType>(
    ['campaignsType', radioValue],
    getCampaignsType,
    {
      enabled: radioValue !== 'registered',
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  React.useEffect(() => {
    if (editCampaign) {
      form.setFieldsValue({
        ...editCampaign,
        start_date: format(editCampaign.start_date, dateTimeFormat),
      });
      setFilters((prevState) => ({
        ...prevState,
        ...editCampaign.filters,
        selected_users: undefined,
      }));
      setRadioValue(editCampaign.users_type);
    }
  }, [editCampaign]);

  React.useEffect(() => {
    updateQuery(filters);
  }, [filters, updateQuery]);

  const mutation = useMutation(id === 'create' ? createCampaign : updateCampaign, {
    onError: (e) => {
      if (e instanceof ValidationError) {
        form.setFields(e.fields);
        notify.error({ title: t('error.someThingIsWrong') });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('campaigns');
      notify.success({ title: t('success.successDataSave') });
      history.push('/campaigns');
    },
  });

  const handleSubmit = (): void => {
    const { start_date, ...rest } = form.getFieldsValue();
    mutation.mutate({
      ...rest,
      start_date: start_date && format(start_date, dateTimeFormatAPI),
      ...(id !== 'create' && { id }),
      filters: {
        ...params,
        page: undefined,
        page_size: undefined,
        birthday__lte:
          birthday_from &&
          yearBirthday(parseInt(birthday_from) || 0)
            .endOf('year')
            .format(dateFormatAPI),
        birthday__gte:
          birthday_to &&
          yearBirthday(parseInt(birthday_to) || 0)
            .startOf('year')
            .format(dateFormatAPI),
        selected_users: selectUser?.join(','),
      },
    });
  };

  const onFilterUsers = React.useMemo(
    () => editCampaign?.filters?.selected_users?.split(',').map((el) => parseInt(el)),
    [editCampaign],
  );

  return (
    <>
      <Space justify="space-between" className="mb-20">
        <Space>
          <h3 className="title">
            {`${id === 'create' ? t(`campaigns.addCampaigns`) : t(`campaigns.editCampaigns`)}`}
          </h3>
        </Space>
        <Space>
          <Link to={'/campaigns'}>
            <Button>{t('buttons.back')}</Button>
          </Link>
        </Space>
      </Space>

      <Card>
        <Form form={form} onFinish={handleSubmit}>
          <Card.Header>
            <Space direction="vertical" align="start">
              <Form.Field name="start_date" className="date-picker" label={t('campaigns.dateTime')}>
                <DatePicker
                  size="large"
                  showTimeSelect
                  isClearable
                  dateFormat={dateTimeFormatInput}
                ></DatePicker>
              </Form.Field>
              <Form.Field className="textarea" name="subject" label={t('campaigns.subject')}>
                <Textarea className="mt-10" placeholder="text here" />
              </Form.Field>
              <Form.Field className="textarea" name="message" label={t('campaigns.messages')}>
                <Textarea className="mt-10" placeholder="text here" />
              </Form.Field>
              <Form.Field
                name="users_type"
                label={t('user.userType')}
                initialValue="registered"
                getValueFromEvent={(v) => {
                  setRadioValue(v);
                  return v;
                }}
              >
                <Radio
                  className="mt-10"
                  options={[
                    { text: `${t(`users.registered`)}`, value: 'registered' },
                    { text: `${t(`users.unregistered`)}`, value: 'unregistered' },
                    { text: `${t(`users.all`)}`, value: 'all' },
                  ]}
                />
              </Form.Field>
            </Space>
          </Card.Header>
          <Card.Body>
            {radioValue === 'registered' ? (
              <>
                <Card>
                  <Card.Header>
                    <UsersTableCampaignsFilter filters={filters} setFilters={setFilters} />
                  </Card.Header>
                  <Loader loading={isLoading} size="regular">
                    <UsersTableCampaigns
                      users={users}
                      setSelectUser={setSelectUser}
                      defaultSelectedUser={onFilterUsers}
                    />
                  </Loader>
                  <Card.Footer>
                    <Pagination size={users?.total_pages || 0} />
                  </Card.Footer>
                </Card>
              </>
            ) : (
              <p className="total-users">{`${t('users.totalUsers')}: ${
                campaignsType?.[radioValue]
              }`}</p>
            )}
          </Card.Body>
          <Card.Footer>
            <Space justify="end">
              <Link to="/campaigns">
                <Button>{t(`buttons.cancel`)}</Button>
              </Link>
              <Button submit type="primary">
                {t(`buttons.save`)}
              </Button>
            </Space>
          </Card.Footer>
        </Form>
      </Card>
    </>
  );
};
