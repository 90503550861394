import { $Object } from 'types';

export const uploadFmDataBanner = (options: $Object): FormData => {
  const { type, image, link, campaign_id, is_active } = options;

  const fmData = new FormData();

  if (type) {
    fmData.append('type', type);
  }
  if (image) {
    fmData.append('image', image);
  }
  if (link) {
    fmData.append('link', link);
  }
  if (campaign_id) {
    fmData.append('campaign_id', campaign_id);
  }

  fmData.append('is_active', is_active);
  return fmData;
};
