import * as React from 'react';
import { Input, Space } from 'ebs-design';
import { InputFilterProps } from '../interface';
import { FiltersContext } from '../Filters';

export const InputFilter: React.FC<InputFilterProps> = ({
  field,
  value,
  min,
  max,
  placeholder,
  className,
}) => {
  const { onChange } = React.useContext(FiltersContext);

  const handleChange = (value: string | number): void => {
    onChange({ [field]: value });
  };

  return (
    <Space className={className}>
      <Input
        min={min}
        max={max}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        isClearable
      />
    </Space>
  );
};
