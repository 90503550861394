import * as React from 'react';
import { Icon, Col, Row } from 'ebs-design';

import { Logo } from 'resources';

interface Props {
  title?: React.ReactNode;
  description?: React.ReactNode;
  footer?: React.ReactNode;
}

export const AuthModal: React.FC<Props> = ({ title, description, footer, children }) => {
  return (
    <div className="authentication-modal">
      <div className="authentication-modal-wrapper">
        <div className="authentication-modal-preview">
          <Icon className="authentication-modal-preview-logo" component={Logo} />
        </div>

        <div className="authentication-modal-form">
          {title || description ? (
            <div className="authentication-modal-form-header">
              <Row>
                <Col size={3}></Col>

                <Col size={9}>
                  {title && <div className="authentication-modal-form-title">{title}</div>}

                  {description && (
                    <div className="authentication-modal-form-description">{description}</div>
                  )}
                </Col>
              </Row>
            </div>
          ) : null}

          <div className="authentication-modal-form-content">{children}</div>

          {footer && <div className="authentication-modal-form-footer">{footer}</div>}
        </div>
      </div>
    </div>
  );
};
