import * as React from 'react';
import { Modal } from 'ebs-design';
import { useTranslation } from 'react-i18next';

interface ImageModalProps {
  onClose: () => void;
  header?: React.ReactNode;
}

export const ImageModal: React.FC<ImageModalProps> = ({ onClose, children, header }) => {
  const { t } = useTranslation();

  const handleCancel = (): void => onClose();

  return (
    <Modal open header={header} onClose={handleCancel}>
      <Modal.Content>{children || <>{t('error.noImage')}</>}</Modal.Content>
    </Modal>
  );
};
