import * as React from 'react';
import { useHistory } from 'react-router-dom';
import SessionContext from './SessionContext';
import { Tokens, ServiceTypes } from 'hooks/useFetcher/interfaces';
import { BootstrapConfig, SessionContextState } from './interface';
import { ProfileUser } from 'types';
import { isCurrentURl } from 'utils';
import { fetchUser, FetchUserResult } from './utils';

interface SessionProviderProps {
  apiConfig: BootstrapConfig;
}

type SPT = React.FC<SessionProviderProps>;

export const accessTokenKey = '__access_token__';

/**
 * This component provides session context down the tree.
 *
 * Use it only once in the application and in the most top
 * level possible.
 */
const SessionProvider: SPT = ({ children, apiConfig }) => {
  const history = useHistory();

  const token = window.localStorage.getItem(accessTokenKey);
  const [session, setSession] = React.useState<string | null>(token);
  const [user, setUser] = React.useState<ProfileUser>();

  React.useEffect(() => {
    if (session) {
      fetchUser(apiConfig[ServiceTypes.MAIN], session).then(
        ([error, response]: FetchUserResult) => {
          if (!error && response) {
            setUser(response);
          }
        },
      );
    }
  }, [apiConfig, session]);

  React.useEffect(() => {
    if (session !== null) {
      window.localStorage.setItem(accessTokenKey, session);
    } else {
      window.localStorage.removeItem(accessTokenKey);
    }
  }, [session]);

  const setTokens = async (tokens: Tokens): Promise<Error | null> => {
    if (tokens) {
      setSession(tokens.access);
    } else if (session) {
      setSession(session);
    }
    return null;
  };

  const removeSession = (): void => setSession(null);
  const contextValue: SessionContextState = {
    session,
    user,
    apiConfig,
    setTokens,
    removeSession,
  };

  if (isCurrentURl('/reset-password/:token') && !session) {
    history.push('/login');
  }

  return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>;
};

export default SessionProvider;
