export enum ServiceTypes {
  MAIN = 'MAIN',
}

export interface ServiceConfig {
  /**
   * The base url of the service.
   */
  baseUrl: string;

  /**
   * The Saas application token
   */
  token?: string;
}

export enum StatusTypes {
  PENDING,
  RESOLVED,
  REJECTED,
}

export enum UserRole {
  SUPER_ADMIN = 'super_admin',
  ADMIN = 'admin',
}
export interface Tokens {
  /**
   * This holds the access tokens, use it
   * for any API request.
   *
   * If the API returns invalid token error,
   * then use the refresh token to get a new `access` token.
   */
  access: string;

  /**
   * This is the refresh token.
   *
   * Only use it after the API has returned an invalid token error.
   */
  refresh: string;
}
