import * as React from 'react';
import { Select, Space } from 'ebs-design';
import { OptionValue } from 'ebs-design/dist/components/molecules/Select/interfaces';
import { FiltersContext } from '../Filters';
import { SelectProps } from '../interface';

export const SelectFilter: React.FC<SelectProps> = ({
  field,
  value,
  placeholder,
  prefix,
  className,
  children,
}) => {
  const { onChange } = React.useContext(FiltersContext);

  const handleChange = (value: OptionValue | OptionValue[]): void => {
    onChange({ [field]: value });
  };

  return (
    <Space className={className}>
      <Select
        className="filter-item"
        value={value}
        onChange={handleChange}
        prefix={prefix}
        placeholder={placeholder}
        isClearable
      >
        {children}
      </Select>
    </Space>
  );
};
