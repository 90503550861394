import * as queryString from 'querystring';
import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { getRequest } from 'utils';

export const useCampaignsAPI = (): any => {
  const fetcher = useFetcher(ServiceTypes.MAIN);

  return {
    getCampaignsType: ({ queryKey: [, query] }) => {
      return fetcher(
        `/dashboard/campaigns/type-counts?${queryString.stringify(query)}`,
        getRequest,
      );
    },
    getCampaigns: ({ queryKey: [, query] }) => {
      return fetcher(`/dashboard/campaigns?${queryString.stringify(query)}`, getRequest);
    },
    getCampaignsById: ({ queryKey: [, { id }] }) =>
      fetcher(`/dashboard/campaigns/${id}`, getRequest),

    createCampaign: (data) => {
      return fetcher('/dashboard/campaigns', {
        data,
        request: {
          method: 'POST',
        },
      });
    },
    updateCampaign: ({ id, ...data }) => {
      return fetcher(`/dashboard/campaigns/${id}`, {
        data,
        request: {
          method: 'PATCH',
        },
      });
    },
    deleteCampaign: (id: number) => {
      return fetcher(`/dashboard/campaigns/${id}`, {
        request: {
          method: 'DELETE',
        },
      });
    },
  };
};
