import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Actions,
  AvatarInline,
  Button,
  Card,
  Icon,
  Loader,
  Space,
  Table,
  useNotify,
  Label,
} from 'ebs-design';
import { Pagination } from 'components/organisms/Pagination';
import { ConfirmModal } from 'components/molecules/ConfirmModal';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { Plus } from 'resources/icons';
import { Campaign, Column, FilterType, Results } from 'types';
import { dateTimeFormat, format } from 'libs';
import { defaultFilters } from 'utils';
import { useCampaignsAPI } from 'api';
import { CampaignsFilter } from '../Filters';
import cn from 'classnames';

export const Campaigns: React.FC = () => {
  const { t } = useTranslation();
  const { getCampaigns, deleteCampaign } = useCampaignsAPI();
  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const [deleteItem, setDeleteItem] = React.useState();

  const { data: campaigns, isLoading } = useQuery<Results<Campaign>>(
    ['campaigns', { ...defaultFilters, ...params }],
    getCampaigns,
    {
      onError: () => notify.error({ title: t('error.someThingIsWrong') }),
    },
  );

  const handleSorting = (type: FilterType, field: string): FilterType => {
    updateQuery({ ...params, ordering: type ? `${type === 'desc' ? '-' : ''}${field}` : type });
    return type;
  };

  const deleteAdminUsersMutation = useMutation(deleteCampaign, {
    onError: () => notify.error({ title: t('error.someThingIsWrong') }),
    onSuccess: () => {
      queryClient.invalidateQueries(['campaigns']);
      notify.success({ title: t('success.successDataDelete') });
      setDeleteItem(undefined);
    },
  });

  const handleDelete = (): void => {
    if (deleteItem) {
      deleteAdminUsersMutation.mutate(deleteItem);
    }
  };

  const handleCancelDelete = (): void => {
    setDeleteItem(undefined);
  };

  const columns: Column<Campaign>[] = React.useMemo(
    () => [
      {
        title: t('campaigns.num'),
        onFilter: (type) => handleSorting(type, 'id'),
        render: ({ id }) => id,
      },
      {
        title: t('campaigns.dateTime'),
        onFilter: (type) => handleSorting(type, 'start_date'),
        render: ({ start_date }) => format(start_date, dateTimeFormat),
      },
      {
        title: t('made_by'),
        onFilter: (type) => handleSorting(type, 'email'),
        render: ({ author }) => (
          <AvatarInline
            circle
            alt={[author?.first_name, author?.last_name].join(' ') || '---'}
            shortAlt={[author?.first_name?.[0], author?.last_name?.[0]].join('')}
            description={author?.email}
          />
        ),
      },
      {
        title: t('campaigns.title'),
        onFilter: (type) => handleSorting(type, 'subject'),
        render: ({ subject, message }) => (
          <>
            <p className="fw-bold">{subject}</p>
            <p>{message}</p>
          </>
        ),
      },
      {
        title: t('campaigns.status'),
        onFilter: (type) => handleSorting(type, 'status'),
        render: ({ status }) => (
          <Label
            type="fill"
            className={cn('status-type', status === 'pending' ? 'inactive' : 'active')}
            text={status === 'pending' ? `${t('campaigns.pending')}` : `${t('campaigns.sent')}`}
          />
        ),
      },
      {
        render: (item) => (
          <Space justify="end">
            <Actions placement="bottom">
              <Link to={`/campaign/${item.id}`}>
                <Actions.Item>{t('campaigns.details')}</Actions.Item>
              </Link>
              {item.status === 'pending' && (
                <Link to={`/campaigns/${item.id}`}>
                  <Actions.Item>{t('buttons.edit')}</Actions.Item>
                </Link>
              )}
              <Actions.Item onClick={() => setDeleteItem(item.id)}>
                {t('buttons.delete')}
              </Actions.Item>
            </Actions>
          </Space>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Space justify="space-between" align="start">
        <CampaignsFilter count={campaigns?.count} />
        <Link to="/campaigns/create">
          <Button type="primary" prefix={<Icon component={Plus} />}>
            {t('buttons.add')}
          </Button>
        </Link>
      </Space>
      <Card>
        <Loader loading={isLoading} size="regular">
          <Table className="table-no-border" data={campaigns?.results} columns={columns} />
        </Loader>
        <Card.Footer>
          <Pagination size={campaigns?.total_pages || 0} />
        </Card.Footer>
      </Card>

      {!!deleteItem && <ConfirmModal onSuccess={handleDelete} onCancel={handleCancelDelete} />}
    </>
  );
};
