// import { LeadStatus } from '../types';

export const statusLabel: {
  [key: string]: {
    status?: 'success' | 'warning' | 'danger' | 'info';
    disabled?: boolean;
  };
} = {
  ['LeadStatus.PROSPECTING']: { status: 'warning' },
  ['LeadStatus.NOT_INTERESTED']: { disabled: true },
  ['LeadStatus.UNQUALIFIED']: { status: 'danger' },
  ['LeadStatus.NOT_ELIGIBLE']: { status: 'info' },
  ['LeadStatus.QUALIFIED']: { status: 'success' },
};
