import * as React from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Upload,
  useForm,
  Input,
  Button,
  Space,
  Modal,
  useNotify,
  DatePicker,
} from 'ebs-design';
import { useSplashScreensAPI, useFileAPI } from 'api';
import { SplashScreens } from 'types';
import { dateTimeFormatInput, format, dateTimeFormat } from 'libs';
import { useUpload } from 'hooks/useUpload';
import { validateResponse } from 'errors/ValidationError/ValidationError';
import { DropZone } from 'components/molecules/DropZone';

interface SplashScreenFormToProps {
  id: number | null;
  onClose: () => void;
}

export const SplashScreenForm: React.FC<SplashScreenFormToProps> = ({ id, onClose }) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [form] = useForm();
  const { downloadFile } = useFileAPI();
  const { getSplashScreenById } = useSplashScreensAPI();
  const { splashScreenPOSTRequest, splashScreenPATCHRequest } = useUpload();

  const { data } = useQuery<SplashScreens>(['splash-screen', { id }], getSplashScreenById, {
    enabled: Boolean(id),
  });
  const [dateFileState, setDateFileState] = React.useState<{ file?: any }>();

  React.useEffect(() => {
    if (data) {
      const { image, start_date, ...rest } = data;

      form.setFieldsValue({
        image: [{ name: image, url: image }],
        start_date: format(start_date, dateTimeFormat),
        ...rest,
      });
      mutation.mutate(image);
    }
  }, [data]);

  const mutation = useMutation<string, unknown, string>((url) => downloadFile(url), {
    onSuccess: (file) => {
      setDateFileState({ file });
    },
  });

  const handleSubmit = (): void => {
    if (id) {
      splashScreenPATCHRequest({
        ...form.getFieldsValue(),
        image: dateFileState?.file,
        id,
      })
        .then(() => {
          onSuccessResponse();
        })
        .catch((error) => {
          validateResponse(form, error);
        });
    } else {
      splashScreenPOSTRequest({ ...form.getFieldsValue(), image: dateFileState?.file })
        .then(() => {
          onSuccessResponse();
        })
        .catch((error) => {
          validateResponse(form, error);
        });
    }
  };

  const handleRequest = (dataFile: any): void => {
    setDateFileState(dataFile);
  };

  const onSuccessResponse = (): void => {
    queryClient.invalidateQueries('splash-screens');
    notify.success({ title: t('success.successDataChange') });
    onClose();
  };

  return (
    <Modal
      open
      size="small"
      header={<h3>{t(!id ? 'modal.addSplashScreen' : 'modal.editSplashScreen')}</h3>}
      onClose={onClose}
    >
      <Modal.Content>
        <Form form={form} onFinish={handleSubmit}>
          <Form.Field name="image">
            <Upload customRequest={handleRequest}>
              <DropZone />
            </Upload>
          </Form.Field>
          <Form.Field name="title" label={t('settingsDashboard.screenSplashName')}>
            <Input />
          </Form.Field>
          <Form.Field name="display_seconds" label={t('form.displaySeconds')}>
            <Input />
          </Form.Field>
          <Form.Field name="start_date" label={t('modal.programmingSplashScreen')}>
            <DatePicker
              size="medium"
              showTimeSelect
              isClearable
              dateFormat={dateTimeFormatInput}
            ></DatePicker>
          </Form.Field>
        </Form>
      </Modal.Content>

      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={onClose}>{t('buttons.cancel')}</Button>
          <Button type="primary" onClick={() => form.submit()}>
            {t('buttons.save')}
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
