import useFetcher from 'hooks/useFetcher/useFetcher';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';

export const useProfileAPI = (): any => {
  const fetcher = useFetcher(ServiceTypes.MAIN);

  return {
    updateProfilePassword: (data) => {
      return fetcher('/dashboard/me', {
        data,
        request: {
          method: 'PATCH',
        },
      });
    },
  };
};
