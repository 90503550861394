import * as React from 'react';
import { DatePicker, Space } from 'ebs-design';
import { DatePickerProps } from '../interface';
import { FiltersContext } from '../Filters';
import { dateFormatInput } from 'libs';
import { DateType } from 'ebs-design/dist/components/molecules/DatePicker/types';

export const DatePickerFilter: React.FC<DatePickerProps> = ({
  field,
  value,
  placeholderText,
  className,
}) => {
  const { onChange } = React.useContext(FiltersContext);

  const handleChange = (date: DateType): void => {
    onChange({ [field]: date });
  };

  return (
    <Space className={className}>
      <DatePicker.RangeInput
        placeholderText={placeholderText}
        dateFormat={dateFormatInput}
        value={value}
        onChange={handleChange}
        isClearable
      />
    </Space>
  );
};
