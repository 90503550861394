import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Table, Space, AvatarInline } from 'ebs-design';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { Column, FilterType, Results, User } from 'types';
import { format, dateTimeFormat } from 'libs';

interface TableProps {
  users?: Results<User>;
}

export const UsersTable: React.FC<TableProps> = ({ users }) => {
  const { t } = useTranslation();
  const { ...params } = useQueryParams();
  const { updateQuery } = useQueryUpdate();

  const handleSorting = (type: FilterType, field: string): FilterType => {
    updateQuery({ ...params, ordering: type ? `${type === 'desc' ? '-' : ''}${field}` : type });
    return type;
  };

  const columns: Column<User>[] = React.useMemo(
    () => [
      {
        title: t('buttons.num'),
        onFilter: (type) => handleSorting(type, 'id'),
        render: ({ id }) => id,
      },
      {
        title: t('users.userName'),
        render: ({ first_name, last_name, email }): React.ReactNode => (
          <AvatarInline
            circle
            alt={[first_name, last_name].join(' ') || '---'}
            shortAlt={[first_name?.[0], last_name?.[0]].join('')}
            description={email}
          />
        ),
      },
      {
        title: t('users.phoneNumber'),
        render: ({ phone_number }) => phone_number,
      },
      {
        title: t('users.shoppingNumber'),
        onFilter: (type) => handleSorting(type, 'len_orders'),
        render: ({ len_orders }) => <div className="td-center">{len_orders}</div>,
      },
      {
        title: `${t('users.shoppingSum')} ${t('ron')}`,
        render: ({ sum_orders }) => (
          <div className="td-center">{sum_orders && parseFloat(sum_orders).toFixed(2)}</div>
        ),
      },
      {
        title: t('users.date'),
        render: ({ created_at }) => format(created_at, dateTimeFormat),
      },
      {
        render: ({ id }) => (
          <Space justify="end">
            <Link to={`/users/${id}`}>
              <Button type="primary">{t('buttons.details')}</Button>
            </Link>
          </Space>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Table className="table-no-border" data={users?.results} columns={columns} />
    </>
  );
};
