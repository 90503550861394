import axios from 'axios';
import { ServiceTypes } from 'hooks/useFetcher/interfaces';
import { useCallback, useContext } from 'react';
import { SessionContext } from 'contexts/SessionContext';

export const useFileAPI = (): any => {
  const { session, apiConfig } = useContext(SessionContext);

  if (!apiConfig) {
    throw new Error('Services configuration was not set, please check Bootstrap component');
  }

  const service = apiConfig[ServiceTypes.MAIN];
  if (!service) {
    throw new Error(`Service config missing for ${ServiceTypes.MAIN}`);
  }

  const fetcher = useCallback(
    async <T>(url: string, opts): Promise<T> => {
      const { data: body, method = 'POST' } = opts;
      const tokenHeaders = {
        Authorization: `Token ${session}`,
      };

      const config = {
        headers: { ...tokenHeaders, 'content-type': 'multipart/form-data' },
      };

      if (method === 'PUT') {
        return await axios.put(`${service.baseUrl}${url}`, body, config);
      }

      if (method === 'PATCH') {
        return await axios.patch(`${service.baseUrl}${url}`, body, config);
      }
      return await axios.post(`${service.baseUrl}${url}`, body, config);
    },
    [session, service],
  );

  return {
    uploadFile: async (file, apiUrl: string, method?: string) => {
      const { data } = await fetcher(apiUrl, {
        data: file,
        method,
      });
      return data;
    },
    downloadFile: async (apiUrl: string) => {
      const { data } = await fetcher(
        apiUrl.includes(`${process.env.REACT_APP_BASE_API}`)
          ? apiUrl.replace(`${process.env.REACT_APP_BASE_API}`, '')
          : apiUrl,
        {},
      );
      return data;
    },
  };
};
