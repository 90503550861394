import { accessTokenKey } from 'contexts/SessionContext/SessionProvider';

/**
 * A concrete error class
 *
 * This errors indicates the the access
 * token from session context is not valid.
 * The user can attempt to get a new access token via a
 * refresh token.
 */
class SessionInvalidError extends Error {
  constructor() {
    super('Session is invalid');
    window.localStorage.removeItem(accessTokenKey);
    window.location.href = '/login';
  }
}

export default SessionInvalidError;
